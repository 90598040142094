import { PolicyModel, WidgetHelped } from '@my-types';
import { DateFNS } from './3rd';
import Consts from './consts';
// import PATH from './path';

type QS = {
	ticket?: string | null;
	token?: string | null;
	delegated?: string | null;
	theme?: string | null;
	authFailTo?: string | null;
	rootUrlFrom?: string | null;
	urlFrom?: string | null;
	parcel?: string | null;
	openid?: string | null;
	planCode?: string | null;
	benefitLevel?: string | null;
	miniProgramSource?: string | null;
	sourceType?: string | null;//保单来源
	thirdDeskHome?: string;
	thirdDeskLogin?: string;
	quotationNo?: string;
	country?: string;
	vendor?: string;
	keyword?: string;
	tag?: string | null;
};

/**
 * copy from https://github.com/AceMetrix/jquery-deparam
 */
const deparam = (params?: string | null, coerce: boolean = false) => {
	var obj: any = {};
	var coerce_types: any = { true: !0, false: !1, null: null };

	// If params is an empty string or otherwise falsy, return obj.
	if (!params) {
		return obj;
	}

	// Iterate over all name=value pairs.
	params
		.replace(/\+/g, ' ')
		.split('&')
		.forEach(function (v) {
			var param: string[] = v.split('=');
			var key: string = decodeURIComponent(param[0]);
			var val: any;
			var cur: any = obj;
			var i: number = 0;
			// If key is more complex than 'foo', like 'a[]' or 'a[b][c]', split it
			// into its component parts.
			var keys: any[] = key.split('][');
			var keys_last: number = keys.length - 1;

			// If the first keys part contains [ and the last ends with ], then []
			// are correctly balanced.
			if (/\[/.test(keys[0]) && /\]$/.test(keys[keys_last])) {
				// Remove the trailing ] from the last keys part.
				keys[keys_last] = keys[keys_last].replace(/\]$/, '');

				// Split first keys part into two parts on the [ and add them back onto
				// the beginning of the keys array.
				keys = keys
					.shift()
					.split('[')
					.concat(keys);

				keys_last = keys.length - 1;
			} else {
				// Basic 'foo' style key.
				keys_last = 0;
			}

			// Are we dealing with a name=value pair, or just a name?
			if (param.length === 2) {
				val = decodeURIComponent(param[1]);

				// Coerce values.
				if (coerce) {
					val =
						val && !isNaN(val) && +val + '' === val
							? +val // number
							: val === 'undefined'
								? undefined // undefined
								: coerce_types[val] !== undefined
									? coerce_types[val] // true, false, null
									: val; // string
				}

				if (keys_last) {
					// Complex key, build deep object structure based on a few rules:
					// * The 'cur' pointer starts at the object top-level.
					// * [] = array push (n is set to array length), [n] = array if n is
					//   numeric, otherwise object.
					// * If at the last keys part, set the value.
					// * For each keys part, if the current level is undefined create an
					//   object or array based on the type of the next keys part.
					// * Move the 'cur' pointer to the next level.
					// * Rinse & repeat.
					for (; i <= keys_last; i++) {
						key = keys[i] === '' ? cur.length : keys[i];
						cur = cur[key] =
							i < keys_last
								? cur[key] || (keys[i + 1] && isNaN(keys[i + 1]) ? {} : [])
								: val;
					}
				} else {
					// Simple key, even simpler rules, since only scalars and shallow
					// arrays are allowed.

					if (Object.prototype.toString.call(obj[key]) === '[object Array]') {
						// val is already an array, so push on the next value.
						obj[key].push(val);
					} else if ({}.hasOwnProperty.call(obj, key)) {
						// val isn't an array, but since a second value has been specified,
						// convert val into an array.
						obj[key] = [obj[key], val];
					} else {
						// val is a scalar.
						obj[key] = val;
					}
				}
			} else if (key) {
				// No value was defined, so set something meaningful.
				obj[key] = coerce ? undefined : '';
			}
		});

	return obj;
};

/**
 * copy from https://github.com/knowledgecode/jquery-param
 */
const param = (a: any): string => {
	var s: string[] = [];
	var add = function (k: string, v: any) {
		v = typeof v === 'function' ? v() : v;
		v = v === null ? '' : v === undefined ? '' : v;
		s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
	};
	var buildParams = function (prefix: string, obj: any) {
		var i, len, key;

		if (prefix) {
			if (Array.isArray(obj)) {
				for (i = 0, len = obj.length; i < len; i++) {
					buildParams(
						prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']',
						obj[i]
					);
				}
			} else if (String(obj) === '[object Object]') {
				for (key in obj) {
					buildParams(prefix + '[' + key + ']', obj[key]);
				}
			} else {
				add(prefix, obj);
			}
		} else if (Array.isArray(obj)) {
			for (i = 0, len = obj.length; i < len; i++) {
				add(obj[i].name, obj[i].value);
			}
		} else {
			for (key in obj) {
				buildParams(key, obj[key]);
			}
		}
		return s;
	};

	return buildParams('', a).join('&');
};
// related: https://en.wikipedia.org/wiki/List_of_file_signatures
const checkType = (header: string): string => {
	if (/^89504e47/.test(header)) {
		return 'png';
	} else if (/^ffd8ff/.test(header)) {
		return 'jpeg, jpg'; // 包含jpg
	} else if (/^47494638/.test(header)) {
		return 'gif';
	} else if (/^25504446/.test(header)) {
		return 'pdf';
	} else if (/^49492a0/.test(header)) {
		return 'tif';
	} else if (/^4d4d002a/.test(header)) {
		return 'tiff';
	} else if (/^504b/.test(header)) {
		return 'docx, xlsx, pptx';
	} else if (/^d0cf11e0/.test(header)) {
		return 'doc, xls, ppt, msg';
	} else {
		return '';
	}
}
/**
 * 工具类
 */
class Utils {
	/**
	 * 将指定的参数转换为数组.
	 * 如果参数是数组, 直接返回.
	 * 如果参数是null/undefined, 返回空数组.
	 * 其他返回只包含一个元素(参数本身)的数组
	 */
	toArray<T>(any: null | undefined | T | T[]): T[] {
		return Array.isArray(any) ? any : any == null ? [] : [any];
	}
	/**
	 * 产生一个包含指定元素和长度的数组
	 */
	times<T>(element: T, times?: number): T[] {
		const ret = new Array<T>(times || 1);
		ret.fill(element, 0, ret.length);
		return ret;
	}
	/**
	 * 截取日期
	 */
	truncateAsDate(date: string): string {
		return date ? date.substring(0, 11) : date;
	}
	/**
	 * 对指定时间进行计算. 如果指定时间为null, 使用当前时间.
	 * offset格式: /[yMdhms][+-]?\d+/
	 */
	calculateDate(date: string | Date, offset: string): string {
		if (date === null) {
			date = new Date();
		} else if (this.isString(date)) {
			date = DateFNS.parse(date);
		}
		const regexp = /[yMdhms][+-]?\d+/g;
		const partRegexp = /([yMdhms])([+-]?)(\d+)/;
		const value = offset.match(regexp)!.reduce((date, operation) => {
			const match = operation.match(partRegexp)!;
			const part = match[1];
			const type = match[2];
			const variable = (match[3] as any) * (type === '-' ? -1 : 1);
			switch (part) {
				case 'y':
					return type === ''
						? DateFNS.setYear(date, variable)
						: DateFNS.addYear(date, variable);
				case 'M':
					return type === ''
						? DateFNS.setMonth(date, variable)
						: DateFNS.addMonth(date, variable);
				case 'd':
					return type === ''
						? DateFNS.setDayOfMonth(date, variable)
						: DateFNS.addDay(date, variable);
				case 'h':
					return type === ''
						? DateFNS.setHour(date, variable)
						: DateFNS.addHour(date, variable);
				case 'm':
					return type === ''
						? DateFNS.setMinute(date, variable)
						: DateFNS.addMinute(date, variable);
				case 's':
					return type === ''
						? DateFNS.setSecond(date, variable)
						: DateFNS.addSecond(date, variable);
				default:
					throw new Error(
						`Field[${type}] in offset[${offset}] for date calculation is not supported yet.`
					);
			}
		}, date);

		return DateFNS.format(value, Consts.STANDARD_DATETIME_FORMAT);
	}
	/**
	 * 金额格式化
	 */
	formatAmount(currency?: number | string, decimal?: number): string {
		let result = ''
		if (currency != null) {
			if (decimal) {
				result = ((typeof currency === 'string' ? parseFloat(currency) : currency).toFixed(decimal) + '').toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
			} else {
				result = (currency + '').toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
			}
		} else {
			result = decimal ? Number(0).toFixed(decimal) + '' : '';
		}
		return result;
	}
	isEmpty(o?: string | number | null): o is null | undefined {
		return !this.isNumber(o) && (this.isNull(o) || this.isUndefined(o) || o.length === 0);
	}
	isBlank(o?: string | number | null): o is string | null | undefined {
		return this.isEmpty(o) || (this.isString(o) && (o as string).trim().length === 0);
	}
	/**
	 * 是否字符串
	 */
	isString(o: any): o is string {
		return Object.prototype.toString.call(o).slice(8, -1) === 'String';
	}
	/**
	 * 是否数字
	 */
	isNumber(o: any): o is number {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Number';
	}
	/**
	 * 是否boolean
	 * @param {*} o
	 */
	isBoolean(o: any): o is boolean {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Boolean';
	}
	/**
	 * 是否函数
	 * @param {*} o
	 */
	isFunction(o: any): o is Function {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Function';
	}
	/**
	 * 是否为null
	 * @param {*} o
	 */
	isNull(o: any): o is null {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Null';
	}
	/**
	 * 是否undefined
	 */
	isUndefined(o: any): o is undefined {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Undefined';
	}
	/**
	 * 是否对象
	 */
	isObject(o: any): o is object {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Object';
	}
	/**
	 * 是否数组
	 */
	isArray(o: any): o is any[] {
		return Array.isArray(o);
	}
	/**
	 * 是否时间
	 */
	isDate(o: any): o is Date {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Date';
	}
	/**
	 * 是否正则
	 */
	isRegExp(o: any): o is RegExp {
		return Object.prototype.toString.call(o).slice(8, -1) === 'RegExp';
	}
	/**
	 * 是否错误对象
	 */
	isError(o: any): o is Error {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Error';
	}
	/**
	 * 是否Symbol函数
	 */
	isSymbol(o: any): o is Symbol {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Symbol';
	}
	/**
	 * 是否Promise对象
	 */
	isPromise(o: any): o is Promise<any> {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Promise';
	}
	/**
	 * 是否Set对象
	 */
	isSet(o: any): o is Set<any> {
		return Object.prototype.toString.call(o).slice(8, -1) === 'Set';
	}
	/**
	 * 是否是FormData对象
	 */
	isFormData(o: any): o is FormData {
		return Object.prototype.toString.call(o).slice(8, -1) === 'FormData';
	}
	/**
	 * 可否可以判断为false
	 */
	isFalse(o: any): o is false {
		return !o || o === 'null' || o === 'undefined' || o === 'false' || o === 'NaN';
	}
	/**
	 * 可否可以判断为true
	 */
	isTrue(o: any): o is true {
		return !this.isFalse(o);
	}
	/**
	 * 指定参数, 则解析; 否则获取当前location的search, 并转换为JSON对象.
	 * 如果没有, 返回空JSON
	 */
	fromQueryString(qs?: string | null): QS {
		if (!this.isNull(qs) && !this.isUndefined(qs)) {
			return deparam(qs);
		} else {
			const search = window.location.search;
			if (search && search !== '?') {
				return deparam(search.substring(1));
			} else {
				return {};
			}
		}
	}
	/**
	 * 将指定的对象转换为query string
	 */
	toQueryString(obj: any): string {
		return param(obj);
	}
	/**
	 * 使用当前浏览器当前URI编码, 并且添加指定的query string
	 */
	encodeCurrentURI(qs?: string | null): string {
		if (this.isNull(qs) || this.isUndefined(qs)) {
			return encodeURIComponent(window.location.origin + window.location.pathname);
		} else {
			return encodeURIComponent(`${window.location.origin}${window.location.pathname}?${qs}`);
		}
	}
	/**
	 * 拼接姓名
	 *
	 * @param {string} firstName
	 * @param {string} lastName
	 */
	asName(firstName: string | null | undefined, lastName: string | null | undefined): string {
		if (this.hasOrientalChar(firstName) || this.hasOrientalChar(lastName)) {
			return (lastName || '') + (firstName || '');
		} else {
			return (firstName || '') + ' ' + (lastName || '');
		}
	}
	/**
	 * 是否包含东方字符
	 */
	hasOrientalChar(s: string | null | undefined): boolean {
		if (!s) {
			return false;
		}
		return (
			-1 !==
			s.split('').findIndex(char => {
				return char.charCodeAt(0) > 0x3040;
			})
		);
	}
	quitPolicyPage(invoker: WidgetHelped, policy: PolicyModel): void {
		const extensionData = policy.extensionData || {};
		if (extensionData.rootUrlFrom) {
			// 从其他地方跳过来的
			window.location.assign(extensionData.rootUrlFrom);
		} else if (extensionData.urlFrom) {
			// 从其他地方跳过来的
			const currentUrl = window.location.href.split('#')[0];
			if (extensionData.urlFrom !== currentUrl) {
				// 与当前url不一样, 直接跳
				window.location.assign(extensionData.urlFrom);
			} else if (window.location.href.indexOf('#') !== -1) {
				// 当前url有#, 退两步
				invoker
					.getHelpers()
					.getRouter()
					.go(-2);
			} else {
				invoker
					.getHelpers()
					.getRouter()
					.back();
			}
		} else {
			invoker
				.getHelpers()
				.getRouter()
				.back();
		}
	}
	/**
	 * 生成文件
	 * TODO 目前支持csv及txt格式, 后续如果需要生成其他格式文件, 例如图片, 再进行丰富及扩展
	 *
	 * @param fileName 文件名称
	 * @param fileType 文件类型, 例如'text/csv'
	 * @param fileContent 符合文件类型的文件内容
	 */
	downloadFile(fileName: string, fileType: string, fileContent: string): void {
		// @ts-ignore
		if (window.navigator.msSaveOrOpenBlob) {
			// if browser is IE
			fileContent = '\ufeff' + fileContent;
			const IEBlob = new Blob([decodeURIComponent(encodeURI(fileContent))], {
				type: fileType + ';charset=utf-8;'
			});
			//@ts-ignore
			navigator.msSaveBlob(IEBlob, fileName);
		} else {
			const link = document.createElement('a');
			// 解决大文件下载失败
			const blob = new Blob(['\ufeff' + fileContent], { type: fileType });
			link.setAttribute('href', URL.createObjectURL(blob));
			link.setAttribute('download', fileName);

			document.body.appendChild(link);
			link.click();
		}
	}

	//废弃
	base64ImagetoFile(base64Image: string, filename: string): File {
		//将base64转换为文件
		var arr = base64Image.split(',')!,
			mime = arr[0].match(/:(.*?);/)![1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}

	//将base64转化成file
	dataURLtoFile(dataurl: string, filename: string): File {
		return this.blobToFile(this.dataURLtoBlob(dataurl), filename);
	}

	//将base64转换为blob
	dataURLtoBlob(dataurl: string): any {
		var arr = dataurl.split(','),
			mime = (arr[0].match(/:(.*?);/) || ['', ''])[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	//将blob转换为file
	blobToFile(theBlob: any, fileName: string): any {
		theBlob.lastModifiedDate = new Date();
		theBlob.name = fileName;
		return theBlob;
	}


	transformPNG2JPEG(base64Image: string): Promise<string> {
		return new Promise(resolve => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d')!;

			const image = new Image();
			image.crossOrigin = 'anonymous';
			image.onload = function () {
				const { width, height } = image;
				canvas.width = width;
				canvas.height = height;
				ctx.fillStyle = '#fff';
				ctx.fillRect(0, 0, width, height);
				ctx.drawImage(image, 0, 0, width, height);
				resolve(canvas.toDataURL('image/jpeg', 1.0));
			};
			image.src = base64Image;
		});
	}
	copyTextToClipboard(s: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const textarea = document.createElement('textarea');
			// 一定要显示, 否则在模拟器上不能复制
			// textarea.style.display = 'none';
			textarea.style.position = 'fixed';
			textarea.style.height = '0';
			textarea.style.top = '-9999px';
			textarea.value = s;
			document.body.appendChild(textarea);
			textarea.select();
			textarea.setSelectionRange(0, s.length);
			const ret = document.execCommand('copy');
			document.body.removeChild(textarea);
			if (ret) {
				resolve();
			} else {
				reject();
			}
		});
	}

	log(message?: any, ...optionalParams: any[]): void {
		console.log(message, optionalParams);
	}
	info(message?: any, ...optionalParams: any[]): void {
		console.info(message, optionalParams);
	}
	trace(message?: any, ...optionalParams: any[]): void {
		console.trace(message, optionalParams);
	}
	warn(message?: any, ...optionalParams: any[]): void {
		console.warn(message, optionalParams);
	}
	error(message?: any, ...optionalParams: any[]): void {
		console.error(message, optionalParams);
	}
	table(...data: any[]): void {
		console.table(data);
	}
	validateIDCard(number: string) {
		if (!number) number = "";
		var card = number.trim();
		var str = "身份证";
		//是否为空
		if (card === '') {
			return '请输入' + str + '号';
		}
		//校验长度，类型
		if (this.isCardNo(card) === false) {
			return '您输入的' + str + '号码不正确，请重新输入';
		}
		//检查省份
		if (this.checkProvince(card) === false) {
			return '您输入的' + str + '号码不正确,请重新输入';
		}
		//校验生日
		if (this.checkBirthday(card) === false) {
			return '您输入的' + str + '号码生日不正确,请重新输入';
		}
	}
	// must input 17 bit string of RID from left to right
	calcChecksum(rid: string) {
		let arr = rid.split('').reverse();
		function W(i: number) {
			return Math.pow(2, i - 1) % 11;
		}
		function S(arr: any[]) {
			let sum = 0;
			for (let j = 0; j < 17; j++) {
				sum += arr[j] * W(j + 2);
			}
			return sum;
		}
		let ret = (12 - (S(arr) % 11)) % 11;
		return ret === 10 ? 'X' : ret;
	}
	//检查号码是否符合规范，包括长度，类型
	isCardNo(card: string) {
		//身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
		// var reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/;
		// if (reg.test(card) === false) {
		//   return false;
		// }
		if (card.length === 18) {
			let lastChar = this.calcChecksum(card.substr(0, 17));
			return lastChar == card[17];
		} else if (card.length === 15) {
			return /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$/.test(card);
		} else {
			return false;
		}
	};
	//取身份证前两位,校验省份
	checkProvince(card: string) {
		var vcity = {
			11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古",
			21: "辽宁", 22: "吉林", 23: "黑龙江", 31: "上海", 32: "江苏",
			33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南",
			42: "湖北", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆",
			51: "四川", 52: "贵州", 53: "云南", 54: "西藏", 61: "陕西", 62: "甘肃",
			63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外"
		};
		var province = card.substr(0, 2);
		//@ts-ignore
		if (this.isUndefined(vcity[province])) {
			return false;
		}
		return true;
	};
	//检查生日是否正确
	checkBirthday(card: string) {
		let len = card.length;
		//身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
		// eslint-disable-next-line
		if (len == 15) {
			let re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
			let arr_data = card.match(re_fifteen);
			//@ts-ignore
			let year = arr_data[2];
			//@ts-ignore
			let month = arr_data[3];
			//@ts-ignore
			let day = arr_data[4];
			let birthday = new Date('19' + year + '/' + month + '/' + day);
			return this.verifyBirthday('19' + year, month, day, birthday);
		}
		//身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
		// eslint-disable-next-line
		if (len == 18) {
			let re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
			let arr_data = card.match(re_eighteen);
			//@ts-ignore
			let year = arr_data[2];
			//@ts-ignore
			let month = arr_data[3];
			//@ts-ignore
			let day = arr_data[4];
			let birthday = new Date(year + '/' + month + '/' + day);
			return this.verifyBirthday(year, month, day, birthday);
		}
		return false;
	};

	//校验日期
	verifyBirthday(year: string, month: string, day: string, birthday: Date) {
		var now = new Date();
		var now_year = now.getFullYear();
		//年月日是否合理
		if (birthday.getFullYear() === Number(year) && (birthday.getMonth() + 1) === Number(month) && birthday.getDate() === Number(day)) {
			//判断年份的范围（0岁到105岁之间)
			//@ts-ignore
			var time = now_year - year;
			if (time <= 105 && now > birthday) {
				return true;
			}
			return false;
		}
		return false;
	};
	getBirthday(card: string) {
		let len = card.length;
		//身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
		// eslint-disable-next-line
		if (len == 15) {
			let re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
			let arr_data = card.match(re_fifteen);
			//@ts-ignore
			let year = arr_data[2];
			//@ts-ignore
			let month = arr_data[3];
			//@ts-ignore
			let day = arr_data[4];
			let birthday = new Date('19' + year + '/' + month + '/' + day);
			return birthday;
		}
		//身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
		// eslint-disable-next-line
		if (len == 18) {
			let re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
			let arr_data = card.match(re_eighteen);
			//@ts-ignore
			let year = arr_data[2];
			//@ts-ignore
			let month = arr_data[3];
			//@ts-ignore
			let day = arr_data[4];
			let birthday = new Date(year + '/' + month + '/' + day);
			return birthday;
		}
		return '';
	}
	getGender(card: string) {
		let genderNumber = card.length === 15 ? card.substr(card.length - 1, 1) : card.substr(card.length - 2, 1);
  		return parseInt(genderNumber) % 2 === 0 ? Consts.GENDER.FEMALE : Consts.GENDER.MALE;
	}
	calculateAge(date: any) {
		const result: { [propName: string]: number } = { years: 0, months: 0, days: 0 };
		const now = new Date();
		let age = this.isDate(date) ? date : new Date(date);
		const years = DateFNS.diffInYears(now, age);
		if (years > 0) {
			result.years = years;
			age = DateFNS.addYear(age, years);
		}
		const months = DateFNS.diffInMonths(now, age);
		if (months > 0) {
			result.months = months
			age = DateFNS.addMonth(age, months);
		}
		const days = DateFNS.diffInDays(now, age);
		if (days > 0) {
			result.days = days
		}
		return result;
	}
	/**
	 * 获取当前ip地址，安卓能获取到，ios获取不到
	 */
	getIPs(): Promise<String> {
		const DEFAULT_IP = '114.114.114.114';
		return new Promise((resolve, reject) => {
			try {
				var pc = new RTCPeerConnection({ iceServers: [] }), noop = function () { };
				pc.createDataChannel('');//create a bogus data channel 
				let options = {
					offerToReceiveAudio: false,
					offerToReceiveVideo: true,
					iceRestart: false
				} as RTCOfferOptions;

				pc.createOffer(options)
					.then((desc) => {
						pc.setLocalDescription(desc)
					})
					.catch((err) => {
						console.log(err)
					});

				pc.onicecandidate = function (ice) {
					if (ice && ice.candidate && ice.candidate.candidate) {
						var ips = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(ice.candidate.candidate);
						console.log('my IP: ', ips);
						if (ips && ips.length > 1) {
							resolve(ips[1]);
						} else {
							resolve(DEFAULT_IP);
						}
					} else {
						resolve(DEFAULT_IP);
					}
					pc.onicecandidate = noop;
				};
			} catch {
				resolve(DEFAULT_IP);
			}
		})


	};


	/**
   * 解决两个数相乘精度丢失问题
   * @param a
   * @param b
   * @returns {Number}
   */
	floatMul(a: number, b: number): number {
		var c = 0,
			d = a.toString(),
			e = b.toString();
		try {
			c += d.split(".")[1].length;
		} catch (f) { }
		try {
			c += e.split(".")[1].length;
		} catch (f) { }
		return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
	}

	/**
   * 解决两个数相除精度丢失问题
   * @param a
   * @param b
   * @returns
   */
	floatDiv(a: number, b: number): number {
		var c, d, e = 0,
			f = 0;
		try {
			e = a.toString().split(".")[1].length;
		} catch (g) { }
		try {
			f = b.toString().split(".")[1].length;
		} catch (g) { }
		// eslint-disable-next-line
		return c = Number(a.toString().replace(".", "")), d = Number(b.toString().replace(".", "")), this.floatMul(c / d, Math.pow(10, f - e));
	}
	/**
	 * 检查文件类型
	 * @patam file 需要被检查的文件
	 * @param {string[]} fileList 允许的文件类型数组，e.g. ['jpg', 'pdf']
	 */
	checkFileType = (file: File, fileList: string[]): Promise<string> => {
		return new Promise((resolve, reject) => {
			let blob = file;
			let fileReader = new FileReader();
			fileReader.onloadend = e => {
				var arr = (new Uint8Array(e.target?.result as ArrayBuffer)).subarray(0, 4);
				var header = "";
				for (var i = 0; i < arr.length; i++) {
					header += arr[i].toString(16);
				}
				// Check the file signature against known types
				let type = checkType(header);
				if (type && fileList.find(item => (type as string).includes(item.toLowerCase()))) {
					resolve('');
				} else {
					reject(`文件格式不正确, 当前${fileList.length === 1 ? '仅' : ''}支持${fileList.join(',').toUpperCase()}格式`);
				}
			};
			fileReader.readAsArrayBuffer(blob);
		});
	}
	iframeMessage = (src = '', method = '', data = {}): Promise<object> => {
		return new Promise((resolve, reject) => {
			const ifr = document.createElement('iframe');
			ifr.src = src;
			ifr.style.display = 'none';
			const parent = document.head || document.body;
			parent.appendChild(ifr);
			ifr.onload = () => {
				if (ifr.contentWindow) {
					ifr.contentWindow.postMessage([method, data], '*');
				}
			};
			const onMessage = (e: MessageEvent) => {
				if (e.data && e.data[0] && e.data[1]) {
					if (e.data[1].success) {
						resolve(e.data[1].data);
					} else {
						reject();
					}
					window.removeEventListener('message', onMessage, false);
					parent.removeChild(ifr);
				}
			};
			window.removeEventListener('message', onMessage, false);
			window.addEventListener('message', onMessage, false);
		});
	}
	strip(num: number, precision = 12) { // 精度问题
		return +parseFloat(num.toPrecision(precision));
	}
	getFileNameFromPath(path = '') {
		let fileNameWithSuffix = path.split('/').pop(),
			name = fileNameWithSuffix?.split('.').shift();
		return name ? decodeURIComponent(name) : '';
	}

	/**
 * 
 * @returns 生成32位uuid
 */
	generateUUID() {
		var s = [] as any[];
		var hexDigits = "0123456789abcdef";
		for (var i = 0; i < 36; i++) {
			s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
		}
		s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
		s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
		s[8] = s[13] = s[18] = s[23] = "-";

		var uuid = s.join("");
		return uuid;
	}


	generateFileType(type: string) {
		switch (type) {
			case 'application/pdf':
				return 'pdf'
			case 'image/jpeg':
				return 'jpg';
			case 'image/png':
				return 'png';
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				return 'xlsx';
			case 'application/zip':
				return 'zip';
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				return 'docx';
			case 'video/x-flv':
				return 'flv';
			case 'text/plain':
				return 'txt';
			case 'application/json':
				return 'json';
			case 'text/html':
				return 'html';
			default:
				return 'txt';
		}

	}

	/**
	 * 
	 * @param value 是否是http://或https://开头的url地址
	 * @returns 
	 */
	 isHttpKey(value: string): boolean {
		return /^http[s]{0,1}:\/\/([\w.]+\/?)\S*/.test(value);
	}

	/**
	 * 
	 * @param str 是否是base64字符传
	 * @returns 
	 */
	isBase64Key(str: string): boolean {
		if (this.isBlank(str)) {
			return false;
		}
		//以data:开始的文件也认为是base64
		if (/^data:/.test(str) && (str as string).indexOf('base64') !== -1) return true;
		try {
			return btoa(atob(str)) == str;
		} catch (err) {
			return false;
		}
	}

	isWebassetKey(value: string): boolean {
		return /^\/webasset\/([\w.]+\/?)\S*/.test(value);
	}

	isStaticKey(value: string): boolean {
		return /^\/static\/([\w.]+\/?)\S*/.test(value);
	}


	//是否是sts的地址,不满足这3者的需要使用sts去请求
	isSTSKey(value: string): boolean {
		return this.isString(value) && !this.isBlank(value) && !this.isHttpKey(value) && !this.isBase64Key(value) && !this.isWebassetKey(value) && !this.isStaticKey(value);
	}

	isJson(str: string) {
		try {
		  	JSON.parse(str);
		} catch (e) {
		  	return false;
		}
		return true;
	}
	maskStr(inputStr: string, firstKeepLen: number, lastKeepLen: number) {
		let str = '';
		if (!this.isBlank(inputStr)) {
			inputStr = inputStr + '';
			if (inputStr.length > (firstKeepLen + lastKeepLen)) {
				str = (firstKeepLen ? inputStr.slice(0, firstKeepLen) : '')
					+ new Array(inputStr.length - firstKeepLen - lastKeepLen).fill('*').join('')
					+ (lastKeepLen ? inputStr.slice(-Math.abs(lastKeepLen)) : '');
			} else {
				str = inputStr;
			}
		}
		return str;
	}
	isValidHttpUrl(str: any) {
		let url: any;
		try {
			url = new URL(str);
		} catch (_) {
			return false;  
		}
		return url.protocol === "http:" || url.protocol === "https:";
	}

	isInvalidItem(item: { [propName: string]: any }) { // category为1且productInfo为空对象代表产品已下架
		return item.category === '1' && (!item.productInfo || item.hasOwnProperty('productInfo') && !Object.keys(item.productInfo).length);
	}
	// "detailConfigType" 产品卡类型( 1.轮播图 2.销量排行 3.热销精选 4.合作保司 5.企业专属（查询租户下所有企业专属列表时必传5）)
	getConfigFromRes(inputConfigList: any[], params: { detailConfigType?: string, isOgran?: boolean }) {
		let { detailConfigType, isOgran } = params;
		let configObj = inputConfigList.find(item => item.detailConfigType === detailConfigType);
		// console.log('configObj', configObj)
		let salesConfig: any[] = configObj ? (configObj.configInfo as any[]).reduce((res, cur) => {
			if (cur.salesConfig) {
				res.push(cur.salesConfig);
			}
			return res;
		}, []) : [];
		let configList: { [propName: string]: any }[] = salesConfig; //?.filter(item => terminal.includes(item.terminal)) 
		// console.log('configList', configList);
		let list = configList.reduce((res, cur) => {
			if (isOgran) {
				if (cur.organExclusives) {
					if (cur.organExclusives?.banners.length) {
						(cur.organExclusives.banners as any[]).forEach(item => {
							item.image = item.image1 || item?.productInfo?.cardImage1; //image1: PC端图片地址 image2: 移动端图片地址
						});
						cur.organExclusives.banners = (cur.organExclusives.banners as any[]).filter(item => !this.isInvalidItem(item));
					}
					if (cur.organExclusives?.products.length) {
						cur.organExclusives.products = (cur.organExclusives.products as any[]).filter(item => !this.isInvalidItem(item)).map(item => {
							item.image = item.image1 || item?.productInfo?.cardImage1; //image1: PC端图片地址 image2: 移动端图片地址
							return item;
						});
					}
					res.push(cur.organExclusives); // 机构终端配置详细信息为单独对象，push一次
				}
			} else {
				cur.terminalConfigs && (cur.terminalConfigs as any[]).forEach(item => {
					item.image = item.image1 || item?.productInfo?.cardImage1; //image1: PC端图片地址 image2: 移动端图片地址
					if (!this.isInvalidItem(item)) {
						res = res.concat(item);
					}
				});
			}
			return res;
		}, []);
		return {
			title: configList[0]?.salesConfigName || '',
			list: (list as any[]).map(item => {
				item.showOverall = false;
				return item;
			})
		}
	}
}

export default new Utils();
