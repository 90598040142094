import { QRCodeGeneratorLib } from '@my-types';

let processor: QRCodeGeneratorLib | null = null;

const QRCodeGenerator = async (): Promise<QRCodeGeneratorLib> => {
	if (processor != null) {
		return processor;
	}
	processor = (await import(/* webpackChunkName: "qrcode-generator" */ 'qrcode.es')) as any;
	return processor as QRCodeGeneratorLib;
};
export default QRCodeGenerator;