import { BeneficiaryType, Gender, ParticipantIdType, PolicyCustomerType, PaymentMethod, PayType, PolicyDetailStatus } from '../types';

enum AgreementStatus {
	DEFINE = 1,
	APPLY = 2,
	ARPPOVE = 3,
	REJECT = 4,
	SHUTDOWN = 5
}
enum PolicyStatus {
	QUOTE = 1,
	ISSUED = 2,
	WAIT_FOR_PAYMENT = 3,
	ADVISE = 5,
	DELETED_ADVISE = 6,
	PAID = 7,
	ISSUING = 8,
	DELETED_QUOTE = 9,
	INSURE_FAILED = 10,
	WAIT_FOR_SUBMIT = 11,
	SUBMITTED = 51,
	PAY_FAILED = 87,
	INVALID = 88,
	WITHDRAWN = 96,
	DECLINE = 97,
	CANCELLED = 98,
	TERMINATED = 99
}
enum TaxType {
	CHINA = 1,
	NOT_CHINA = 2,
	BOTH = 3
}


enum CurrencySign {
	CNY = '¥'
}
enum ProductFunctionType {
	ADVICE = '1',
	QUOTE = '2',
	BOTH = '3'
}
enum AccountType {
	ADMIN = '1',
	USER = '2'
}
/**
 * 账号状态
 */
enum AccountEnabled {
	INVALID = '0', //失效
	VALID = '1', //有效
	WAIT_APPROVE = '2', //待审核
	WAIT_GRANT_AUTH = '3', //待授权
	FAIL_APPROVE = '4', //不通过
	LOCK = '5', //锁定
}
enum TenantType {
	INSURANCE_COMPANY = '1',
	BROKER = '2',
	AGENT = '3',
	VIRTUAL = '4',
	SYSTEM_OWNER = '5'
}
enum TenantIds {
	SYSTEM_OWNER = '1',
	DEMO_BROKER = '2',
	DEMO_AGENT = '3',
	DEMO_INSURANCE_COMPANY = '5',
	VIRTUAL = '4'
}
enum MEMBERCLASSIFICATION {
	ENTERPRISE_WHITELISTwhitelist = '1',
	ENTERPRISE_NOWHITELISTwhitelist = '2',
	PERSONAGE = '3'
}
enum PolicyPropertyNames {
	ERROR_DESCRIPTION = 'extensionData.errorsDescription',
	HEALTH_NOTICE_SERIA_NO = 'extensionData.healthNoticeSeriaNo',
	RESEND_FLAG = 'extensionData.reSendFlag',
	WIZARD_STEP_NAME = 'extensionData.step',
	FORCE_CREATE_FLAG = 'extensionData.forceCreate',
	CALCULATION_FLAG = 'extensionData.calculatePassed',
	ILLUSTRATION = 'extensionData.illustration',
	// CLPCP_STATUS = 'extensionData.status',
	DETAIL_STATUS = 'extensionData.status',
	TIPS = 'extensionData.tips',
	NEED_SYNC = 'extensionData.needSync',
	ROOT_URL_FROM = 'extensionData.rootUrlFrom',
	URL_FROM = 'extensionData.urlFrom',
	PARCEL = 'extensionData.parcel',
	OPENID = 'extensionData.openid',
	READ_DECLARATION = 'extensionData.readDeclaration',
	PAYMENT_METHOD = 'extensionData.paymentInfo.paymentMethod'
}
enum SalesArea {
	UNCONCERNED = '99999999'
}

enum CoverageType {
	MAIN = 'M',
	RIDER = 'R'
}

enum ImageFileType {
	JPG = 'jpg',
	JPEG = 'jpeg',
	PNG = 'png'
}
enum ClaimTel {
	DEFAULT = '',
	PICC_GI = '95518',
	CPIC_GI = '95500',
	PINGAN_GI = '95511'
}
enum ClaimTip {
	DEFAULT = '',
	PICC_GI = '请拨打中国人民财产保险股份有限公司全国统一服务热线',
	CPIC_GI = '请拨打中国太平洋财产保险股份有限公司全国统一服务热线',
	PINGAN_GI = '请拨打中国平安财产保险股份有限公司全国统一服务热线'
}
enum TenantCode {
	PICC_GI = 'PICC_GI',
	CPIC_GI = 'CPIC_GI',
	PINGAN_GI = 'PINGAN_GI'
}

enum ErrorCode {
	PASSWORD_NOT_MATCH = '密码不正确，请重新输入！',
	PASSWORD_INCORRECT_PATTERN = '密码修改失败, 请阅读密码规则！'
}


export default {
	// 异常
	FETCH_ERROR: 'FETCH_ERROR',
	// 认证
	AUTH_KEY: 'Authorization',
	CSMD_TOKEN_KEY: 'x-csmd-token',
	CSMD_DELEGATED_KEY: 'x-csmd-delegated',
	// 版本
	CSMS_APP_VER_KEY: 'x-csms-app-ver',
	BACKEND_VER_STORAGE_KEY: 'backend-ver',
	// 主题
	THEME_KEY: 'name',
	THEME_COLOR_KEY: 'themeColor',
	// 账户
	ACCOUNT_KEY: 'Account',
	ACCOUNT_SIGN_KEY: 'Signin',
	SHORTCUTS_FLOATING_KEY: 'shortcutsFloating',
	TRUST_KEY_KEY: 'TrustKey',
	/** 从哪个入口登录的, 专指登录页. 不同租户可能有不同登录首页. 默认登录首页不会记录 */
	ACCOUNT_SIGN_IN_FROM: 'AccountSigninFrom',
	/** 从北京海力的登录首页进入, 值与租户代码必须保持一致 */
	ACCOUNT_SIGN_IN_FROM_BJHL: 'BJHL',
	// 格式
	STANDARD_DATETIME_FORMAT: 'YYYY/MM/DD HH:mm:ss',
	STANDARD_DATE_FORMAT: 'YYYY/MM/DD',
	W3C_DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
	W3C_DATE_FORMAT: 'YYYY-MM-DD',
	// 保单属性
	POLICY_PROPERTIES: PolicyPropertyNames,
	// 租户序列号
	TENANT_IDS: TenantIds,
	// 租户类型
	TENANT_TYPE: TenantType,
	// 账户类型
	ACCOUNT_TYPE: AccountType,
    ACCOUNT_ENABLED: AccountEnabled,
	// 产品功能类型
	PRODUCT_FUNC_TYPE: ProductFunctionType,
	AGREEMENT_STATUS: AgreementStatus,
	// 保单状态
	POLICY_STATUS: PolicyStatus,
	POLICY_DETAIL_STATUS: PolicyDetailStatus,
	// POLICY_STATUS_CLPCP: CLPCPPolicyStatus,
	PAYMENT_METHOD: PaymentMethod,
	PAY_TYPE: PayType,
	ID_TYPE: ParticipantIdType,
	TAX_TYPE: TaxType,
	CUSTOMER_TYPE: PolicyCustomerType,
	BENEFICIARY_TYPE: BeneficiaryType,
	GENDER: Gender,
	CURRENCY_SIGN: CurrencySign,
	SALES_AREA: SalesArea,
	COVERAGE_TYPE: CoverageType,
	//最大日期
	MAX_DATE: '9999/12/31 00:00:00',
	ADVICE_DATA: 'AdviceData',
	//允许的图片类型
	IMAGE_FILE_TYPE: ImageFileType,
	CLAIM_TEL: ClaimTel,
	CLAIM_TIP: ClaimTip,
	TENANT_CODE: TenantCode,
	ErrorCode:ErrorCode,
	// 会员分类
	MEMBER_CLASSIFICATION: MEMBERCLASSIFICATION,
	//@ts-ignore
	STS_FOLDER: `csms2_${process.env.REACT_APP_ENV_NAME.toLowerCase()}`,
	STS_SIGNATURE_EXPIRES: 1800,
};
