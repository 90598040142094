import diffInYears from 'date-fns/difference_in_years';
import diffInDays from 'date-fns/difference_in_days';
import diffInMonths from 'date-fns/difference_in_months';
import diffInSeconds from 'date-fns/difference_in_seconds';
import diffInMinutes from 'date-fns/difference_in_minutes';
import diffInHours from 'date-fns/difference_in_hours';
/**
 * M: 1, 2, ..., 12
 * Mo: 1st, 2nd, ..., 12th
 * MM: 01, 02, ..., 12
 * MMM: Jan, Feb, ..., Dec
 * MMMM: January, February, ..., December
 * Q: 1, 2, 3, 4
 * Qo: 1st, 2nd, 3rd, 4th
 * D: 1, 2, 3, ..., 31
 * Do: 1st, 2nd, ..., 31st
 * DD: 01, 02, ..., 31
 * DDD: 1, 2, ..., 366
 * DDDo: 1st, 2nd, ..., 366th
 * DDDD: 001, 002, ..., 366
 * d: 0, 1, ... 6
 * do: 0th, 1st, ... 6th
 * dd: Su, Mo, ... Sa
 * ddd: Sun, Mon, ..., Sat
 * dddd: Sunday, Monday, ..., Saturday
 * E(Day of ISO Week): 1, 2, ..., 7
 * W(ISO Week): 1, 2, ..., 53
 * Wo: 1st, 2nd, ..., 53rd
 * WW: 01, 02, ... 53
 * YY: 00, 01, ..., 99
 * YYYY: 1900, 1901, ..., 2099
 * GG(ISO Week-numbering Year): 00, 01, ..., 99
 * GGGG: 1900, 1901, ..., 2099
 * A(AM/PM): AM, PM
 * a: am, pm
 * aa: a.m., p.m.
 * H: 0, 1, ..., 23
 * HH: 00, 01, ..., 23
 * h: 1, 2, ..., 12
 * hh: 01, 02, ..., 12
 * m: 0, 1, ..., 59
 * mm: 00, 01, ... 59
 * s: 0, 1, ..., 59
 * ss: 00, 01, ... 59
 * S(1/10 of Second): 0, 1, ..., 9
 * SS(1/100 of Second): 00, 01, ..., 99
 * SSS: 000, 001, ..., 999
 * Z: -01:00, +00:00, ..., +12:00
 * ZZ: -0100, +0000, ..., +1200
 * X(Seconds Timestamp): 512969520
 * x(Milliseconds Timestamp): 51296520900
 */
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfToday from 'date-fns/start_of_today';
import startOfDay from 'date-fns/start_of_day';
import endOfToday from 'date-fns/end_of_today';
import endOfDay from 'date-fns/end_of_day';
import addYear from 'date-fns/add_years';
import addMonth from 'date-fns/add_months';
import addDay from 'date-fns/add_days';
import addHour from 'date-fns/add_hours';
import addMinute from 'date-fns/add_minutes';
import addSecond from 'date-fns/add_seconds';
import setYear from 'date-fns/set_year';
import setMonth from 'date-fns/set_month';
import setDayOfMonth from 'date-fns/set_date';
import setHour from 'date-fns/set_hours';
import setMinute from 'date-fns/set_minutes';
import setSecond from 'date-fns/set_seconds';
import subSeconds from 'date-fns/sub_seconds';

const DateFNS = {
	diffInYears,
	diffInDays,
	diffInMonths,
	format,
	parse,
	startOfToday,
	startOfDay,
	endOfToday,
	endOfDay,
	addYear,
	addMonth,
	addDay,
	addHour,
	addMinute,
	addSecond,
	setYear,
	setMonth,
	setDayOfMonth,
	setHour,
	setMinute,
	setSecond,
	subSeconds,
	diffInSeconds,
	diffInMinutes,
	diffInHours
};

export default DateFNS;
