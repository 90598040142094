import { CodeItem } from '@my-types';
import Consts from './consts';

type CODES = {
	/** 布尔值true/false */
	BOOLEAN: CodeItem[];
	/** 布尔值Y/N */
	BOOLEAN_YN: CodeItem[];
	/** 布尔值'1'/'0' */
	BOOLEAN_10: CodeItem[];
	/** 租户类型 */
	TENANT_TYPE: CodeItem[];
	/** 产品支持类型 */
	PRODUCT_FUNCTION_TYPE: CodeItem[];
	CURRENCY: CodeItem[];
	/** 币种符号 */
	CURRENCY_SIGN: CodeItem[];
	/** 合约状态 */
	AGREEMENT_STATUS: CodeItem[];
	/** 支持的页面主题 */
	THEME: CodeItem[];
	/** 课程类型 */
	COURSE_TYPE: CodeItem[];
	/** 课程状态 */
	COURSE_STATUS: CodeItem[];

	/** 订单状态 */
	POLICY_STATUS: CodeItem[];

	/** 订单明细状态 */
	DETAIL_STATUS: CodeItem[];

	/** 性别 */
	GENDER: CodeItem[];
	/** 婚姻状况 */
	MARITAL_STATUS: CodeItem[];
	/** 保单递送方式 */
	DELIVERY_METHOD: CodeItem[];
	/** 国籍 */
	NATIONALITY: CodeItem[];
	/** 证件类型 */
	ID_TYPE: CodeItem[];
	/** 税收类型 */
	TAX_TYPE: CodeItem[];
	/** 客户类型 */
	CUSTOMER_TYPE: CodeItem[];

	STATEMENT_SOURCE_TYPE: CodeItem[];

	FILE_TYPE:CodeItem[];
	/** 租户名称 */
	TENANT_NAME: CodeItem[];
	/* 市场类型 */
	MARKET_TYPE: CodeItem[];
	/* 产品用途 */
    PRODUCT_USAGES: CodeItem[];
    /* 产品警示方式 */
	PRODUCT_WARNINGS: CodeItem[];
	readonly [propName: string]: CodeItem[];
	
	/** 会员分类 */
	MEMBER_CLASSIFICATION: CodeItem[];
};

export default {
	BOOLEAN: [{ l: '是', v: true }, { l: '否', v: false }],
	BOOLEAN_YN: [{ l: '是', v: 'Y' }, { l: '否', v: 'N' }],
	BOOLEAN_10: [{ l: '是', v: '1' }, { l: '否', v: '0' }],
	BOOLEAN_EFFECT: [{ l: '有效', v: true }, { l: '失效', v: false }],

	BOOLEAN_MEMBER_EFFECT: [
		{ l: '失效', v: false },
		{ l: '有效', v: true },
		{ l: '注销', v: '注销' },
		{ l: '锁定', v: '锁定' }
	],
	MEMBER_CLASSIFICATION:[
		{ l: '企业白名单',v:Consts.MEMBER_CLASSIFICATION.ENTERPRISE_WHITELISTwhitelist },
		{ l: '企业非白名单',v:Consts.MEMBER_CLASSIFICATION.ENTERPRISE_NOWHITELISTwhitelist },
		{ l: '个人',v:Consts.MEMBER_CLASSIFICATION.PERSONAGE },
	],
	BOOLEAN_HAS: [{ l: '有', v: true }, { l: '无', v: false }],
	TENANT_TYPE: [
		{ l: '保险公司', v: Consts.TENANT_TYPE.INSURANCE_COMPANY },
		{ l: '经纪公司', v: Consts.TENANT_TYPE.BROKER },
		{ l: '代理公司', v: Consts.TENANT_TYPE.AGENT },
		{ l: '虚拟租户', v: Consts.TENANT_TYPE.VIRTUAL },
		{ l: '系统持有人', v: Consts.TENANT_TYPE.SYSTEM_OWNER }
	],
	TENANT_NAME: [
		{ l: '中国人民财产保险股份有限公司广东省分公司', v: Consts.TENANT_CODE.PICC_GI },
		{ l: '中国太平洋财产保险股份有限公司广东分公司', v: Consts.TENANT_CODE.CPIC_GI },
		{ l: '中国平安财产保险股份有限公司广东分公司', v: Consts.TENANT_CODE.PINGAN_GI }
	],
	PRODUCT_FUNCTION_TYPE: [{ l: '仅建议书', v: 1 }, { l: '仅电投', v: 2 }, { l: '电投+建议书', v: 3 }],
	AGREEMENT_STATUS: [
		{ v: 1, l: '定义中' },
		{ v: 2, l: '申请中' },
		{ v: 3, l: '审核通过' },
		{ v: 4, l: '审核驳回' },
		{ v: 5, l: '已关闭' }
	],
	CURRENCY: [{ v: 'CNY', l: '人民币' }],
	CURRENCY_SIGN: [
		{ v: 'CNY', l: '¥' },
		{ v: 'USD', l: '$' },
		{ v: 'EUR', l: '€' },
		{ v: 'HKD', l: 'HK$' },
		{ v: 'GBP', l: '£' },
		{ v: 'JPY', l: '¥' },
		{ v: 'TWD', l: 'NT$' }
	],
	THEME: [
		{ l: '中国红', v: 'china' },
		{ l: '波特兰红', v: 'portland' },
		{ l: '泰格橙', v: 'tiger' },
		{ l: '奥林匹克蓝', v: 'olympics' }
	],
	COURSE_TYPE: [{ v: 1, l: '必修课程' }, { v: 2, l: '选修课程' }, { v: 3, l: '线下课程' }, { v: 4, l: '视频' }],
	COURSE_STATUS: [{ v: 1, l: '启用' }, { v: 2, l: '禁用' }],
	POLICY_STATUS: [
		{ l: '待提交', v: '1' }, // 报价中
		{ l: '已承保', v: '2' },
		{ l: '待支付', v: '3' },
		{ l: '建议书', v: '5' },
		{ l: '已支付未出单', v: '7' },
		{ l: '出单中', v: '8' },
		{ l: '待提交', v: '10' },
		{ l: '支付失败', v: '87' },
		{ l: '已撤单', v: '96' },
		{ l: '已退保', v: '98' },
		{ l: '已满期', v: '99' },
		{ l: '已拒保', v: '97' }
	],
	DETAIL_STATUS: [
		{ v: '1', l: '已删除' },
		{ v: '11', l: '待提交' },
		{ v: '51', l: '投保提交' },
		{ v: '101', l: '人工核保中' },
		{ v: '102', l: '投保失败' },
		{ v: '106', l: '自核通过' },
		{ v: '107', l: '核保通过' },
		{ v: '109', l: '其他' },
		{ v: '110', l: '核保中' },
		{ v: '112', l: '自核失败' },
		{ v: '115', l: '拒保' },
		{ v: '120', l: '退费中' },
		{ v: '121', l: '退费成功' },
		{ v: '122', l: '退费失败' },
		{ v: '203', l: '回执审核中' },
        { v: '204', l: '回访成功' },
        { v: '205', l: '回访失败' },
        { v: '207', l: '无需回访' },
		{ v: '201', l: '已承保' },
		{ v: '202', l: '已回执' },
		{ v: '114', l: '待支付' },
		{ v: '104', l: '转账收费中' },
		{ v: '105', l: '转账失败' },
		{ v: '108', l: '转账成功' },
		{ v: '103', l: '已撤单' },
		{ v: '501', l: '失效' },
		{ v: '502', l: '终止' },
		{ v: '504', l: '已退保' },
		{ v: '505', l: '犹豫期退保' }
	],
	GENDER: [{ v: Consts.GENDER.MALE, l: '男' }, { v: Consts.GENDER.FEMALE, l: '女' }],
	MARITAL_STATUS: [{ v: 1, l: '未婚' }, { v: 2, l: '已婚' }, { v: 3, l: '离异' }, { v: 4, l: '丧偶' }],
	DELIVERY_METHOD: [{ v: 1, l: '电子保单' }, { v: 2, l: '纸质保单' }],
	NATIONALITY: (() => {
		const data = {
			CHN: '中国',
			TWN: '中国台湾',
			HKG: '中国香港',
			MAC: '中国澳门',
			ALB: '阿尔巴尼亚',
			DZA: '阿尔及利亚',
			AFG: '阿富汗',
			ARG: '阿根廷',
			ARE: '阿联酋',
			ABW: '阿鲁巴',
			OMN: '阿曼',
			AZE: '阿塞拜疆',
			EGY: '埃及',
			ETH: '埃塞俄比亚',
			IRL: '爱尔兰',
			EST: '爱沙尼亚',
			AND: '安道尔',
			AGO: '安哥拉',
			AIA: '安圭拉',
			ATG: '安提瓜和巴布达',
			AUT: '奥地利',
			ALA: '奥兰群岛',
			AUS: '澳大利亚',
			BRB: '巴巴多斯',
			PNG: '巴布亚新几内亚',
			BHS: '巴哈马',
			PAK: '巴基斯坦',
			PRY: '巴拉圭',
			PSE: '巴勒斯坦',
			BHR: '巴林',
			PAN: '巴拿马',
			BRA: '巴西',
			BLR: '白俄罗斯',
			BMU: '百慕大',
			BGR: '保加利亚',
			MNP: '北马里亚纳群岛',
			BEN: '贝宁',
			BEL: '比利时',
			ISL: '冰岛',
			PRI: '波多黎各',
			POL: '波兰',
			BIH: '波斯尼亚和黑塞哥维那',
			BOL: '玻利维亚',
			BLZ: '伯利兹',
			BWA: '博茨瓦纳',
			BTN: '不丹',
			BFA: '布基纳法索',
			BDI: '布隆迪',
			BVT: '布韦岛',
			PRK: '朝鲜',
			GNQ: '赤道几内亚',
			DNK: '丹麦',
			DEU: '德国',
			TLS: '东帝汶',
			TGO: '多哥',
			DOM: '多米尼加',
			DMA: '多米尼克',
			RUS: '俄罗斯',
			ECU: '厄瓜多尔',
			ERI: '厄立特里亚',
			FRA: '法国',
			FRO: '法罗群岛',
			PYF: '法属波利尼西亚',
			GUF: '法属圭亚那',
			ATF: '法属南部领地',
			MAF: '法属圣马丁',
			VAT: '梵蒂冈',
			PHL: '菲律宾',
			FJI: '斐济群岛',
			FIN: '芬兰',
			CPV: '佛得角',
			GMB: '冈比亚',
			COG: '刚果（布）',
			COD: '刚果（金）',
			COL: '哥伦比亚',
			CRI: '哥斯达黎加',
			GRD: '格林纳达',
			GRL: '格陵兰',
			GEO: '格鲁吉亚',
			GGY: '根西岛',
			CUB: '古巴',
			GLP: '瓜德罗普',
			GUM: '关岛',
			GUY: '圭亚那',
			KAZ: '哈萨克斯坦',
			HTI: '海地',
			KOR: '韩国',
			NLD: '荷兰',
			BES: '荷兰加勒比区',
			HMD: '赫德岛和麦克唐纳群岛',
			MNE: '黑山',
			HND: '洪都拉斯',
			KIR: '基里巴斯',
			DJI: '吉布提',
			KGZ: '吉尔吉斯斯坦',
			GIN: '几内亚',
			GNB: '几内亚比绍',
			CAN: '加拿大',
			GHA: '加纳',
			GAB: '加蓬',
			KHM: '柬埔寨',
			CZE: '捷克',
			ZWE: '津巴布韦',
			CMR: '喀麦隆',
			QAT: '卡塔尔',
			CYM: '开曼群岛',
			CCK: '科科斯群岛',
			COM: '科摩罗',
			CIV: '科特迪瓦',
			KWT: '科威特',
			HRV: '克罗地亚',
			KEN: '肯尼亚',
			COK: '库克群岛',
			LVA: '拉脱维亚',
			LSO: '莱索托',
			LAO: '老挝',
			LBN: '黎巴嫩',
			LTU: '立陶宛',
			LBR: '利比里亚',
			LBY: '利比亚',
			LIE: '列支敦士登',
			REU: '留尼汪',
			LUX: '卢森堡',
			RWA: '卢旺达',
			ROU: '罗马尼亚',
			MDG: '马达加斯加',
			IMN: '马恩岛',
			MDV: '马尔代夫',
			FLK: '马尔维纳斯群岛（福克兰）',
			MLT: '马耳他',
			MWI: '马拉维',
			MYS: '马来西亚',
			MLI: '马里',
			MKD: '马其顿',
			MHL: '马绍尔群岛',
			MTQ: '马提尼克',
			MYT: '马约特',
			MUS: '毛里求斯',
			MRT: '毛里塔尼亚',
			USA: '美国',
			UMI: '美国本土外小岛屿',
			ASM: '美属萨摩亚',
			VIR: '美属维尔京群岛',
			MNG: '蒙古国',
			MSR: '蒙塞拉特岛',
			BGD: '孟加拉国',
			PER: '秘鲁',
			FSM: '密克罗尼西亚联邦',
			MMR: '缅甸',
			MDA: '摩尔多瓦',
			MAR: '摩洛哥',
			MCO: '摩纳哥',
			MOZ: '莫桑比克',
			MEX: '墨西哥',
			NAM: '纳米比亚',
			ZAF: '南非',
			ATA: '南极洲',
			SGS: '南乔治亚岛和南桑威奇群岛',
			SSD: '南苏丹',
			NRU: '瑙鲁',
			NPL: '尼泊尔',
			NIC: '尼加拉瓜',
			NER: '尼日尔',
			NGA: '尼日利亚',
			NIU: '纽埃',
			NOR: '挪威',
			NFK: '诺福克岛',
			PLW: '帕劳',
			PCN: '皮特凯恩群岛',
			PRT: '葡萄牙',
			JPN: '日本',
			SWE: '瑞典',
			CHE: '瑞士',
			SLV: '萨尔瓦多',
			WSM: '萨摩亚',
			SRB: '塞尔维亚',
			SLE: '塞拉利昂',
			SEN: '塞内加尔',
			CYP: '塞浦路斯',
			SYC: '塞舌尔',
			SAU: '沙特阿拉伯',
			BLM: '圣巴泰勒米岛',
			CXR: '圣诞岛',
			STP: '圣多美和普林西比',
			SHN: '圣赫勒拿',
			KNA: '圣基茨和尼维斯',
			LCA: '圣卢西亚',
			SMR: '圣马力诺',
			SPM: '圣皮埃尔和密克隆',
			VCT: '圣文森特和格林纳丁斯',
			LKA: '斯里兰卡',
			SVK: '斯洛伐克',
			SVN: '斯洛文尼亚',
			SJM: '斯瓦尔巴群岛和扬马延岛',
			SWZ: '斯威士兰',
			SDN: '苏丹',
			SUR: '苏里南',
			SLB: '所罗门群岛',
			SOM: '索马里',
			TJK: '塔吉克斯坦',
			THA: '泰国',
			TZA: '坦桑尼亚',
			TON: '汤加',
			TCA: '特克斯和凯科斯群岛',
			TTO: '特立尼达和多巴哥',
			TUN: '突尼斯',
			TUV: '图瓦卢',
			TUR: '土耳其',
			TKM: '土库曼斯坦',
			TKL: '托克劳',
			WLF: '瓦利斯和富图纳',
			VUT: '瓦努阿图',
			GTM: '危地马拉',
			VEN: '委内瑞拉',
			BRN: '文莱',
			UGA: '乌干达',
			UKR: '乌克兰',
			URY: '乌拉圭',
			UZB: '乌兹别克斯坦',
			ESP: '西班牙',
			ESH: '西撒哈拉',
			GRC: '希腊',
			SGP: '新加坡',
			NCL: '新喀里多尼亚',
			NZL: '新西兰',
			HUN: '匈牙利',
			SYR: '叙利亚',
			JAM: '牙买加',
			ARM: '亚美尼亚',
			YEM: '也门',
			IRQ: '伊拉克',
			IRN: '伊朗',
			ISR: '以色列',
			ITA: '意大利',
			IND: '印度',
			IDN: '印度尼西亚',
			GBR: '英国',
			VGB: '英属维尔京群岛',
			IOT: '英属印度洋领地',
			JOR: '约旦',
			VNM: '越南',
			ZMB: '赞比亚',
			JEY: '泽西岛',
			TCD: '乍得',
			GIB: '直布罗陀',
			CHL: '智利',
			CAF: '中非'
		} as { [propName: string]: string };
		return Object.keys(data).map(key => {
			return { v: key, l: data[key] };
		});
	})(),
	//客户类型
	CUSTOMER_TYPE: [
		{ v: Consts.CUSTOMER_TYPE.HOLDER, l: '投保人' },
		{ v: Consts.CUSTOMER_TYPE.INSURED, l: '被保人' },
		{ v: Consts.CUSTOMER_TYPE.BENEFICIARY, l: '受益人' }
	],
	//证件类型
	ID_TYPE: [
		{ v: Consts.ID_TYPE.ID_CARD, l: '身份证' },
		{ v: Consts.ID_TYPE.FAMILY_REGISTER, l: '户口本' },
		{ v: Consts.ID_TYPE.BIRTH_CERTIFICATE, l: '出生证明' },
		{ v: Consts.ID_TYPE.DRIVE_LICENSE, l: '驾驶证' },
		{ v: Consts.ID_TYPE.MILITARY_ID, l: '军官证' },
		{ v: Consts.ID_TYPE.PASSPORT, l: '护照' },
		{ v: Consts.ID_TYPE.HK_PASS, l: '港澳通行证' },
		{ v: Consts.ID_TYPE.TW_PASS, l: '台湾通行证' },
		// { v: Consts.ID_TYPE.FOREIGN_PASSPORT, l: '外国护照' },
		{ v: Consts.ID_TYPE.FOREIGN_REGISTER_CARD, l: '居住证' },
		{ v: Consts.ID_TYPE.HK_MAINLAND, l: '港澳居民来往内地通行证' },
		{ v: Consts.ID_TYPE.TW_MAINLAND, l: '台湾居民来往大陆通行证' },
		{ v: Consts.ID_TYPE.HK_RESIDENT_PERMIT, l: '港澳居民居住证' },
		{ v: Consts.ID_TYPE.TW_RESIDENT_PERMIT, l: '台湾居民居住证' },
		{ v: Consts.ID_TYPE.PERMANENT_RESIDENT_PERMIT, l: '外国人永久居留身份证' },
		{ v: Consts.ID_TYPE.TEMPORARY_ID_CARD, l: '临时身份证' },
		{ v: Consts.ID_TYPE.SOLDIER_ID_CARD, l: '士兵证' },
		{ v: Consts.ID_TYPE.OTHERS, l: '其他' }
	],
	// 海旅证件类型
	GTA_ID_TYPE: [
		{ v: Consts.ID_TYPE.ID_CARD, l: '身份证' },
		{ v: Consts.ID_TYPE.PASSPORT, l: '中国护照' },
		{ v: Consts.ID_TYPE.FOREIGN_PASSPORT, l: '外国护照' },
	],
	TAX_TYPE: [
		{ v: Consts.TAX_TYPE.CHINA, l: '仅为中国税收居民' },
		{ v: Consts.TAX_TYPE.NOT_CHINA, l: '仅为非居民' },
		{ v: Consts.TAX_TYPE.BOTH, l: '既是中国税收居民又是其他国家（地区）税收居民' }
	],
	PAYMENT_METHOD:[
		{v:Consts.PAYMENT_METHOD.CASH,l:'现金'},
		{v:Consts.PAYMENT_METHOD.CHEQUE,l:'支票'},
		{v:Consts.PAYMENT_METHOD.REAL_TIME,l:'银行转账'},//时时转账
		{v:Consts.PAYMENT_METHOD.CREDIT_CARD,l:'信用卡'},
		{v:Consts.PAYMENT_METHOD.WECHAT_PAY,l:'微信支付'},
		{v:Consts.PAYMENT_METHOD.BATCH_OFFLINE,l:'线下支付'},
		{v:Consts.PAYMENT_METHOD.UNION_WEB_PAY,l:'银联网页支付'},
		{v:Consts.PAYMENT_METHOD.OTHER,l:'其他'},
	],
	COVERAGE_TYPE:[
		{v:Consts.COVERAGE_TYPE.MAIN,l:'主险'},
		{v:Consts.COVERAGE_TYPE.RIDER,l:'附加险'},
	],
	STATEMENT_SOURCE_TYPE:[{v:'0',l:'toA渠道'},{v:'3',l:'toAtoC渠道'},{v:'4',l:'toC渠道'}],
	FILE_TYPE:[{v:'image/jpeg',l:'JPEG'},{v:'image/png',l:'PNG'},{v:'application/pdf',l:'PDF'}],
	MARKET_TYPE: [{ l: '成熟市场', v: '1' }, { l: '新产品', v: '2' }],
	PRODUCT_USAGES: [
		{ v: '1', l: '汽车关键安全部分' }, { v: '2', l: '飞行器' }, { v: '3', l: '船舶' },
		{ v: '4', l: '发电' }, { v: '5', l: '化工' }, { v: '6', l: '石化' }, { v: '7', l: '医药' },
		{v: '8', l: '均不适用'}
    ],
    PRODUCT_WARNINGS: [
        { v: '1', l: '危险部位警告' }, { v: '2', l: '产品说明书' }, { v: '3', l: '其他' }
    ],
	PL_BENEFIT_LEVELS: [{ v: 1, l: '方案A' }, { v: 2, l: '方案B' }, { v: 3, l: '方案C' }, { v: 4, l: '方案D' }],
    GTA_BENEFIT_LEVELS: [{ v: 'PlanA', l: '全年计划A' }, { v: 'PlanB', l: '全年计划B' }, { v: 'PlanC', l: '全年计划C' }],
    GTA_COVERAGE_PERIODS: [{ v: 'DAYS_90', l: '一年多次（每次行程90天内）' }, { v: 'DAYS_180', l: '一年多次（每次行程180天内）' }]
} as CODES;
