type AuthCodes = {
	readonly DESK_INDEX: string;
	readonly DESK_MODIFY_PWD: string;
	readonly DESK_EXIT: string;

	readonly DESK_DASHBOARD: string;
	readonly DESK_TENANT: string;
	readonly DESK_ORGANAZATION: string;
	readonly DESK_PARTNER: string;
	readonly DESK_PRODUCT_SALES_MANAGEMENET: string;

	readonly DESK_PAGE_MANAGEMENET_PRODUCT_CARD:string;
	readonly DESK_PAGE_MANAGEMENET_MARGETING:string;
	readonly DESK_PAGE_MANAGEMENET_PARTNER_MANAGE:string;
	readonly DESK_PAGE_MANAGEMENET_NET_PRODUCT_REVEAL:string;
	readonly DESK_PAGE_MANAGEMENET_PARTNER_REVEAL:string;

	readonly DESK_VENDOR_MANAGEMENET:string;

	readonly DESK_TENANT_SETTING: string;

	readonly DESK_MEMBER: string;
	readonly DESK_ADMINACCOUNT: string;
	readonly DESK_Role: string;
};
// 权限代码
export default {
	DESK_INDEX: 'desk-index',
	DESK_MODIFY_PWD: 'desk-modify-pwd',
	DESK_EXIT: 'desk-exit',


	DESK_DASHBOARD:'desk-dashboard',
	DESK_TENANT: 'desk-tenant',
	DESK_ORGANAZATION: 'desk-organazation',
	DESK_PARTNER: 'desk-partner',
	DESK_PRODUCT_SALES_MANAGEMENET:'desk-product-sales-management',

	DESK_PAGE_MANAGEMENET_PRODUCT_CARD:'desk-page-management-product-card',
	DESK_PAGE_MANAGEMENET_MARGETING:'desk-page-management-margeting',
	DESK_PAGE_MANAGEMENET_PARTNER_MANAGE:'desk-page-management-partner-manage',
	DESK_PAGE_MANAGEMENET_NET_PRODUCT_REVEAL:'desk-page-management-net-product-reveal',
	DESK_PAGE_MANAGEMENET_PARTNER_REVEAL:'desk-page-management-partner-reval',

	DESK_VENDOR_MANAGEMENET:'desk-vendor-management',


	DESK_TENANT_SETTING: 'desk-tenant-setting',

	DESK_MEMBER: 'desk-member',
	DESK_ADMINACCOUNT:'desk-aminaccount',
	DESK_Role:'desk-role',

} as AuthCodes;
