import md5, { fromByteArray } from './md5';
import ajax from './ajax';
import Apis from "./apis";
import crypto from 'crypto';
import urlutil from 'url';
import Utils from './utils';
import Envs from './envs';
import { Ajax } from '.';
export interface OssStsToken {
    ossEndpoint: string;
    ossRegion: string;
    ossBucket: string;
    accessKeyId: string;
    accessKeySecret: string;
    securityToken: string;
}

export type THeaderEncoding = 'utf-8' | 'latin1';


const DEFAULT_OSS_STS_TOKEN_TIMEOUT = (process.env.ENV_OSS_STS_TOKEN_TIMEOUT || (25 * 60 * 1000)) as number;

const HEADER_ENCODING = 'utf-8';


class OssKit {
    public readonly stsTokenTimout: number;

    private stsToken: OssStsToken | null = null;
    private stsTokenRetrieveTime: number = -1;

    constructor(
        others?: {
            stsTokenTimeout?: number,
        }) {

        const {
            stsTokenTimeout = DEFAULT_OSS_STS_TOKEN_TIMEOUT
        } = others || {};

        this.stsTokenTimout = stsTokenTimeout;
    }



    public async uploadFile(blob: Blob, fileLocation: string, directAuthToken?: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (evt) => {
                console.log('start to upload file:', fileLocation);
                await this.fetchOssStsToken(directAuthToken);
                if (!this.stsToken) {
                    reject('获取STS Token失败');
                }
                const content = (evt.target! as any).result;
                // @ts-ignore
                await this.doUploadFile(content, `${fileLocation}`, this.stsToken!)
                    .then(url => {
                        resolve(url);
                    })
                    .catch(error => {
                        reject(error);
                    });
            };
            reader.readAsArrayBuffer(blob);
        })
    }

    public async fetchOssStsToken(directAuthToken?: string): Promise<void> {
        if (this.stsTokenRetrieveTime === -1 || (Date.now() - this.stsTokenRetrieveTime > this.stsTokenTimout)) {
            const currentTime = Date.now();
            await this.askOssStsToken('ALL', directAuthToken).then(ossStsToken => {
                this.stsToken = ossStsToken;
            }).catch(error => {
                console.error(error);
            });
            this.stsTokenRetrieveTime = currentTime;
            // console.log('Oss sts token retrieved:', this.stsToken);
        }
    };

    private getUploadType = (name: string): string => {
        const endName = name.substring(name.lastIndexOf('.') + 1);
        let type = 'text/plain';
        switch (endName.toLowerCase()) {
            case 'pdf':
                type = 'application/pdf';
                break;
            case 'jpg':
                type = 'image/jpeg';
                break;
            case 'jpeg':
                type = 'image/jpeg';
                break;
            case 'png':
                type = 'image/png';
                break;
            default:

        }
        return type;
    };

    private doUploadFile = async (file: Buffer, fileName: string, stsToken: OssStsToken): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            // @ts-ignore
            const date = new Date().toGMTString();
            const host = `${stsToken.ossBucket}.${stsToken.ossRegion}.aliyuncs.com`;
            const filepath = fileName.split('/').map(segment => encodeURIComponent(segment)).join('/');
            const url = `https://${host}/${filepath}`;
            // console.log(url);
            //目前都是都当成在普通浏览器中
            // if (Envs.isWechat()) {
            //     const bodyString = file.toString("utf8");
            //     const bodyMd5 = md5(bodyString);
            //     const bodyMd5Base64 = fromByteArray(bodyMd5);
            //     const conentType = `${this.getUploadType(fileName)};charset=utf-8`;

            //     const headers1 = {
            //         'content-length': `${file.length}`,
            //         'content-md5': bodyMd5Base64,
            //         'Content-Type': conentType,
            //         host: host,
            //         // GMT time
            //         'x-oss-date': date,
            //         'x-oss-security-token': stsToken.securityToken,
            //     };
            //     const request = {
            //         headers: headers1
            //     };

            //     const canonicalString = this.buildCanonicalString('PUT', `/${stsToken.ossBucket}/${fileName}`, request);
            //     console.log(`canonicalString = ${canonicalString}`);
            //     //@ts-ignore
            //     const signature = this.computeSignature(stsToken.accessKeySecret, canonicalString, HEADER_ENCODING);
            //     const authorization = `OSS ${stsToken.accessKeyId}:${signature}`;

            //     const headers = {
            //         'content-length': `${file.length}`,
            //         'content-type': conentType,
            //         'content-md5': bodyMd5Base64,
            //         host: host,
            //         // GMT time
            //         'x-oss-date': date,
            //         'x-oss-security-token': stsToken.securityToken,
            //         authorization: authorization
            //     };

            //     // 微信小程序环境
            //     // @ts-ignore
            //     import(/* webpackChunkName: "wechat-sdk" */ 'wechat-js-sdk-s').then(data => {
            //         const wx = data.default;
            //         wx.request({
            //             url,
            //             method: 'PUT',
            //             header: headers,
            //             data: bodyString,
            //             success: (res: { statusCode: number;url:string }) => {
            //                 const { statusCode } = res;
            //                 if (statusCode === 200) {
            //                     // 微信小程序环境，response格式待确定，暂不支持
            //                     // console.error('微信小程序环境，response格式待确定，暂不支持');
            //                     resolve(res.url);
            //                 } else {
            //                     reject();
            //                 }
            //             },
            //             fail: () => {
            //                 reject();
            //             }
            //         });
            //     });
            // } else {
                // 一般浏览器环境
                //const bodyMd5 = md5(file);
                //const bodyMd5Base64 = fromByteArray(bodyMd5);
                const bodyMd5Base64 = crypto
                    .createHash('md5')
                    // @ts-ignore
                    .update(Buffer.from(file, 'utf8'))
                    .digest('base64');
                console.log(`bodyMd5Base64 [${bodyMd5Base64}]`);
                const conentType = `${this.getUploadType(fileName)}`;

                const headers = {
                    'content-length': `${file.length}`,
                    'content-md5': bodyMd5Base64,
                    'Content-Type': conentType,
                    host: host,
                    // GMT time
                    'x-oss-date': date,
                    'x-oss-security-token': stsToken.securityToken,
                    authorization: ''
                };

                const canonicalString = this.buildCanonicalString('PUT',
                    `/${stsToken.ossBucket}/${fileName}`, { headers: headers });
                console.log(`canonicalString = ${canonicalString}`);
                //@ts-ignore
                const signature = this.computeSignature(stsToken.accessKeySecret, canonicalString, HEADER_ENCODING);
                headers.authorization = `OSS ${stsToken.accessKeyId}:${signature}`;

                fetch(url, {
                    method: 'PUT',
                    headers,
                    body: file
                }).then(response => {
                    if (response.status === 200) {
                        resolve(response.url);
                    } else {
                        reject('上传影像失败');
                    }
                }).catch(error => {
                    console.error(error);
                    reject('上传影像失败');
                });
            // }
        });
    };

    private askOssStsToken = (type: string, directAuthToken?: string): Promise<OssStsToken> => {
        return new Promise<OssStsToken>((resolve, reject) => {
            let url = `${Apis.STS_ASK_TOKEN}?type=${type}`;
            let isIgnoreAuth = false;
            if (directAuthToken) {
                url += `&${directAuthToken}`;
                isIgnoreAuth = true;
            }
            if (Ajax.isAuthorized()) {
                ajax.get(url, null, { ignoreAuth: isIgnoreAuth, ignoreRetrieveAccount: true })
                    .then(response => {
                        if (response) {
                            if (response.body) {
                                if (response.body.returnCode === 'RC-00001') {
                                    resolve(response.body.body);
                                } else {
                                    reject(response.body.errors[0]);
                                }
                            } else {
                                reject(response.error);
                            }
                        } else {
                            reject('获取STS Token失败');
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            }
        });
    };

    private computeSignature = (accessKeySecret: string, canonicalString: string, headerEncoding: BufferEncoding | THeaderEncoding = 'utf-8'): string => {
        const signature = crypto.createHmac('sha1', accessKeySecret);
        return signature.update(Buffer.from(canonicalString, headerEncoding)).digest('base64');
    };

    private buildCanonicalString = (method: string, resourcePath: string, request: any, expires?: string) => {
        request = request || {};
        const headers = this.lowercaseKeyHeader(request.headers);
        const OSS_PREFIX = 'x-oss-';
        // @ts-ignore
        const ossHeaders = [];
        const headersToSign = {};

        let signContent = [
            method.toUpperCase(),
            // @ts-ignore
            headers['content-md5'] || '',
            // @ts-ignore
            headers['content-type'],
            // @ts-ignore
            expires || headers['x-oss-date']
        ];

        Object.keys(headers).forEach((key) => {
            const lowerKey = key.toLowerCase();
            if (lowerKey.indexOf(OSS_PREFIX) === 0) {
                // @ts-ignore
                headersToSign[lowerKey] = String(headers[key]).trim();
            }
        });

        Object.keys(headersToSign).sort().forEach((key) => {
            // @ts-ignore
            ossHeaders.push(`${key}:${headersToSign[key]}`);
        });
        // @ts-ignore
        signContent = signContent.concat(ossHeaders);

        signContent.push(this.buildCanonicalizedResource(resourcePath, request.parameters));

        return signContent.join('\n');
    };

    private isObject = (obj: any) => {
        return Object.prototype.toString.call(obj) === '[object Object]';
    };

    private isArray = (obj: any) => {
        return Object.prototype.toString.call(obj) === '[object Array]';
    };

    private lowercaseKeyHeader = (headers: object): object => {
        const lowercaseHeader = {};
        if (this.isObject(headers)) {
            Object.keys(headers).forEach(key => {
                // @ts-ignore
                lowercaseHeader[key.toLowerCase()] = headers[key];
            });
        }
        return lowercaseHeader;
    };

    private buildCanonicalizedResource = (resourcePath: string, parameters: any): string => {
        let canonicalizedResource = `${resourcePath}`;
        let separatorString = '?';

        if (typeof parameters === 'string' && parameters.trim() !== '') {
            canonicalizedResource += separatorString + parameters;
        } else if (this.isArray(parameters)) {
            parameters.sort();
            canonicalizedResource += separatorString + parameters.join('&');
        } else if (parameters) {
            const compareFunc = (entry1: any, entry2: any) => {
                if (entry1[0] > entry2[0]) {
                    return 1;
                } else if (entry1[0] < entry2[0]) {
                    return -1;
                }
                return 0;
            };
            const processFunc = (key: any) => {
                canonicalizedResource += separatorString + key;
                if (parameters[key]) {
                    canonicalizedResource += `=${parameters[key]}`;
                }
                separatorString = '&';
            };
            Object.keys(parameters).sort(compareFunc).forEach(processFunc);
        }

        return canonicalizedResource;
    };


    public signatureUrl = async (name: string, options: {
        method?: string;
        expires?: number;
        directAuthToken?: string;
        [propName: string]: any;
    }): Promise<string> => {
        return new Promise(async (resolve, reject) => {
            await this.fetchOssStsToken(options.directAuthToken);
            if (!this.stsToken) {
                return reject('获取STS Token失败');
            }
            if (Utils.isBlank(name) || Utils.isNull(name)) {
                return reject('name is null');
            }
            const stsToken = this.stsToken as OssStsToken;
            name = this._objectName(name);
            options.method = options.method || 'GET';
            const expires = this.timestamp() + (options.expires || 1800);
            const params = {
                bucket: stsToken.ossBucket,
                object: name,
                stsToken
            } as {
                bucket: string;
                object: string;
                stsToken: OssStsToken
            };

            const resource = this._getResource(params);

            if (stsToken.securityToken) {
                options['security-token'] = stsToken.securityToken;
            }

            const signRes = this._signatureForURL(stsToken.accessKeySecret, options, resource, expires);

            const url = urlutil.parse(this._getReqUrl(params));
            //@ts-ignore
            url.query = {
                OSSAccessKeyId: stsToken.accessKeyId,
                Expires: expires,
                Signature: signRes.Signature
            };
            this.copyTo(signRes.subResource, url.query, true);
            //@ts-ignore
            return resolve(`https:${url.format()}`);
        });


    }

    private _objectName = (name: string): string => {
        if(Utils.isNull(name) || Utils.isUndefined(name)){
            throw `name is error:${name}`;
        }
        return name.replace(/^\/+/, '');
    };

    private timestamp = (t?: number): number => {
        if (t) {
            var v = t;
            if (typeof v === 'string') {
                v = Number(v);
            }
            if (String(t).length === 10) {
                v *= 1000;
            }
            return new Date(v).getTime();
        }
        return Math.round(Date.now() / 1000);
    };

    private _getResource = (params: {
        bucket: string;
        object: string;
    }): string => {
        let resource = '/';
        if (params.bucket) resource += `${params.bucket}/`;
        if (params.object) resource += this.encoder(params.object, HEADER_ENCODING);

        return resource;
    };

    private encoder(str: string, encoding: THeaderEncoding = 'utf-8') {
        if (encoding === 'utf-8') return str;
        return Buffer.from(str).toString('latin1');
    }

    private _signatureForURL(accessKeySecret: string,
        options: {
            [propName: string]: any;
        } = {}, resource: string, expires: number, headerEncoding?: THeaderEncoding) {
        const headers = {} as {
            [propName: string]: any;
        };
        const { subResource = {} } = options;

        if (options.process) {
            const processKeyword = 'x-oss-process';
            subResource[processKeyword] = options.process;
        }

        if (options.trafficLimit) {
            const trafficLimitKey = 'x-oss-traffic-limit';
            subResource[trafficLimitKey] = options.trafficLimit;
        }

        if (options.response) {
            Object.keys(options.response).forEach((k) => {
                const key = `response-${k.toLowerCase()}`;
                subResource[key] = options.response[k];
            });
        }

        Object.keys(options).forEach((key) => {
            const lowerKey = key.toLowerCase();
            const value = options[key];
            if (lowerKey.indexOf('x-oss-') === 0) {
                headers[lowerKey] = value;
            } else if (lowerKey.indexOf('content-md5') === 0) {
                headers[key] = value;
            } else if (lowerKey.indexOf('content-type') === 0) {
                headers[key] = value;
            }
        });

        if (Object.prototype.hasOwnProperty.call(options, 'security-token')) {
            subResource['security-token'] = options['security-token'];
        }

        if (Object.prototype.hasOwnProperty.call(options, 'callback')) {
            const json = {
                callbackUrl: encodeURI(options.callback.url),
                callbackBody: options.callback.body
            } as {
                [propName: string]: any;
            };
            if (options.callback.host) {
                json.callbackHost = options.callback.host;
            }
            if (options.callback.contentType) {
                json.callbackBodyType = options.callback.contentType;
            }
            subResource.callback = Buffer.from(JSON.stringify(json)).toString('base64');

            if (options.callback.customValue) {
                const callbackVar = {} as {
                    [propName: string]: any;
                };
                Object.keys(options.callback.customValue).forEach((key) => {
                    callbackVar[`x:${key}`] = options.callback.customValue[key];
                });
                subResource['callback-var'] = Buffer.from(JSON.stringify(callbackVar)).toString('base64');
            }
        }

        const canonicalString = this.buildCanonicalString(options.method, resource, {
            headers,
            parameters: subResource
        }, `${expires}`);

        return {
            Signature: this.computeSignature(accessKeySecret, canonicalString, headerEncoding),
            subResource
        };
    };

    private _getReqUrl(params: {
        bucket: string;
        object: string;
        stsToken: OssStsToken
    }) {
        const { stsToken } = params;
        const ep: any = {};

        if (params.bucket) {
            ep.host = `${stsToken.ossEndpoint}`;
        }

        let resourcePath = '/';

        if (params.object) {
            resourcePath += this._escape(params.object).replace(/\+/g, '%2B');
        }
        ep.pathname = resourcePath;

        const query = {};

        ep.query = query;

        return urlutil.format(ep);
    }

    private _escape = (name: string) => {
        return encodeURIComponent(name).replace(/%2F/g, '/');
    };

    private copyTo(src: any, to: any, withAccess: boolean = true) {
        to = to || {};

        if (!src) return to;
        var keys = Object.keys(src);

        if (!withAccess) {
            for (var i = 0; i < keys.length; i++) {
                key = keys[i];
                if (to[key] !== undefined) continue;
                to[key] = src[key];
            }
            return to;
        }

        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];
            if (!this.notDefined(to, key)) continue;
            var getter = src.__lookupGetter__(key);
            var setter = src.__lookupSetter__(key);
            if (getter) to.__defineGetter__(key, getter);
            if (setter) to.__defineSetter__(key, setter);

            if (!getter && !setter) {
                to[key] = src[key];
            }
        }
        return to;
    };

    private notDefined(obj: any, key: string) {
        return obj[key] === undefined
            && obj.__lookupGetter__(key) === undefined
            && obj.__lookupSetter__(key) === undefined;
    }

}

export const OssKitHelper = (() => {
    let instance = null as any;
    return {
        getInstance: function () {
            if (!instance) {
                instance = new OssKit();
            }
            return instance;
        }
    }
})();

//加载页面时初始化
OssKitHelper.getInstance().fetchOssStsToken();