/**
 * 强类型限制, 增加属性必须加上类型限制以及readonly
 * 如果没有显式定义类型, 则其他文件引用后无法提示语法
 */
type APIS = {
	readonly AUTH_SIGNIN: string;

	readonly CHANGE_PWD: string;
	readonly RESET_PWD: string;
	readonly AUTH_RETRIEVE: string;

	readonly ACCOUNT_CURRENT: string;
	readonly ACCOUNT_CREATE: string;
	readonly ACCOUNT_UPDATE: string;
	readonly ACCOUNT_LIST: string;
	readonly ACCOUNT_LOAD: string;
	readonly ACCOUNT_SECURE_INFO_LOAD: string;
	readonly ACCOUNT_UPLOAD_SYNC: string;
	readonly ACCOUNT_MENU_BY_TENANT_LOAD: string;
	readonly ACCOUNT_MENU_BY_TENANT_UPDATE: string;
	readonly ACCOUNT_CHECK_SALES_AUTH: string;
	readonly ACCOUNT_INFO: string;
	readonly ACCOUNT_BIND_ORG: string;

	readonly VERIFICATION_GENERATE_IMAGE: string;
	readonly VERIFICATION_SEND_MAIL: string;
	readonly VERIFICATION_CODE: string;

	readonly STS_ASK_TOKEN: string;
	readonly STS_ASK_URL: string;

	readonly HOME_PRODUCTS: string;
	readonly GET_MESSAGE_LIST: string;

	readonly REGISTER_VERIFICATION_SEND_MAIL: string;
	readonly REGISTER_VERIFICATION_CODE: string;

	readonly QUERY_QUOTE: string;
	readonly QUERY_POLICY: string;
	readonly QUERY_ENDO: string;

	readonly UPLOAD_EXCEL: string;
	readonly DOWNLOAD_EXCEL: string;

	readonly TENANT_CURRENT: string;
	readonly TENANT_CREATE: string;
	readonly TENANT_UPDATE: string;
	readonly TENANT_PROFILE_UPDATE: string;
	readonly TENANT_LOAD: string;
	readonly TENANT_LIST: string;
	readonly TENANT_DISABLE: string;
	readonly TENANT_ENABLE: string;
	readonly TENANT_LIST_ALL: string;
	readonly TENANT_CONFIG_UPDATE: string;
	readonly TENANT_CONFIG_LIST: string;

	readonly ORGAN_CURRENT: string;
	readonly ORGAN_CREATE: string;
	readonly ORGAN_UPDATE: string;
	readonly ORGAN_LOAD: string;
	readonly ORGAN_LOAD_BYCODE: string;
	readonly ORGAN_LIST: string;
	readonly ORGAN_LIST_FOR_CREATE: string;
	readonly ORGAN_LIST_FOR_EDIT: string;
	readonly ORGAN_UPLOAD_SYNC: string;
	readonly ORGAN_LIST_TREE: string;

	readonly VENDOR_LIST: string;
	readonly VENDOR_AVAILABLE_LIST: string;
	readonly VENDOR_LIST_CHANNEL:string;
	readonly DASHBOARD_LOAD:string;
	readonly DASHBOARD_GENERATE:string;

	readonly PRODUCT_LIST_BY_VENDOR: string;
	readonly PRODUCT_LIST:string;

	readonly AGREE_PRODUCT_LIST: string;
	readonly AGREE_PRODUCT_ENABLE: string;
	readonly AGREE_PRODUCT_DISABLE: string;
	readonly AGREE_PRODUCT_LOAD: string;
	readonly AGREE_PRODUCT_CHANNEL_LIST_PRODUCT: string;
	readonly AGREE_PRODUCT_CHANNEL_UPDATE: string;

	readonly ORGAN_DISABLE: string;
	readonly ORGAN_ENABLE: string;

	readonly AUTH_SIGNUP2: string;
	readonly AUTH_SIGNIN2: string;
	readonly AUTH_SMS_SIGNIN2: string;
	readonly SEND_VALIDATE_CODE: string;
	readonly REFRESH_PIC_CODE: string;
	readonly VERIFY_PIC_CODE: string;
	readonly CODE_VALIDATION: string;
	readonly CHECK_VALIDATION_CODE: string
	readonly RESET_PWD2: string;
	readonly ORGAN_QUERY: string;

	readonly TENANT_CONFIGURATION_LIST: string;
	readonly COOPERATIVE_INSURER_DISCLOSURE: string;


	readonly PRODUCT_DISCLOSURE: string;
	// 会员管理 & 管理员账号管理
	readonly MEMBER_LIST:string;
	readonly MEMBER_EDIT:string;
	readonly MEMBER_LOOK:string;
	readonly MEMBER_DISABLE: string;
	readonly MEMBER_ENABLE: string;
	readonly MEMBER_ADD: string;
	readonly ADMIN_LIMITS:string;
	readonly ADMIN_ROLE:string;
	// 角色管理
	readonly ROLE_LIST:string;
	readonly ROLE_LIST_ROLE:string;
	

	readonly PRODUCT_DISCLOSURE_DISABLE: string;
	readonly PRODUCT_DISCLOSURE_ENABLE: string;
	readonly PRODUCT_DISCLOSURE_LOAD: string;
	readonly PRODUCT_DISCLOSURE_UPDATE: string;

	readonly PRODUCT_VENDOR_DISCLOSURE: string;
	readonly PRODUCT_VENDOR_DISCLOSURE_DISABLE: string;
	readonly PRODUCT_VENDOR_DISCLOSURE_ENABLE: string;
	readonly PRODUCT_VENDOR_DISCLOSURE_LOAD: string;
	readonly PRODUCT_VENDOR_DISCLOSURE_UPDATE: string;

	readonly QUERY_PRODUCT_LIST: string;
	readonly QUERY_PRODUCT_LIST_BY_KEYWORD: string;
	readonly QUERY_PRODUCT_LIST_BY_KEYWORD_NOT_LOGIN: string;
	readonly DIRECT_ASK: string;
	readonly QUERY_PRODUCT_TAG: string;

	readonly QUERY_ACCOUNT_MASK: string;
	readonly QUERY_ACCOUNT_INFO: string;
	readonly UPDATE_ACCOUNT: string;
	readonly MODIFY_MOBILE: string;
	readonly DISABLE_ACCOUNT: string;

	readonly POLICY_LIST_BY_STATUS: string;
	readonly ACCOUNT_AVATAR: string;

	readonly POLICE_VERIFY_WITHOUT_FACE: string;
	readonly AGREE_PRODUCT_CHANNEL_LIST_ORGAN_LIST_ORGAN_AVAILABLE: string;

	readonly CREATE_SEARCH_HISTORY: string;
	readonly QUERY_SEARCH_HISTORY: string;
	readonly DELETE_SEARCH_HISTORY: string;

	readonly SEND_EMAIL: string;

	readonly FAMILY_MEMBER_LIST_ACCOUNT_ID: string;
	readonly FAMILY_MEMBER_UPDATE: string;
	readonly FAMILY_MEMBER_DELETE: string;

	readonly GET_RENEW_POLICYIDS: string;
	readonly GET_VENDOR_URL: string;
};
/**
 * 所有API路由声明
 */
export default {
	AUTH_SIGNIN: '/rest/v1/auth/signin',
	CHANGE_PWD: '/rest/v1/auth/changep',
	RESET_PWD: '/rest/v1/auth/restp',
	AUTH_RETRIEVE: '/rest/v1/auth/retrieve',

	ACCOUNT_CREATE: '/rest/v1/auth/register4Enterprise',
	ACCOUNT_UPDATE: '/rest/v1/auth/update4Enterprise',
	ACCOUNT_LOAD: '/rest/v1/auth/getAccountInfo',
	ACCOUNT_SECURE_INFO_LOAD: '/rest/v1/account/secure/',
	ACCOUNT_UPLOAD_SYNC: '/rest/v1/account/uploadSync',
	ACCOUNT_MENU_BY_TENANT_LOAD: '/rest/v1/account/menus/tenant/load/',
	ACCOUNT_MENU_BY_TENANT_UPDATE: '/rest/v1/account/menus/tenant/update',
	ACCOUNT_CHECK_SALES_AUTH: '/rest/v1/account/checkAgentSaleAuthority/',
	ACCOUNT_INFO: '/rest/v1/auth/getAccountInfo',
	ACCOUNT_BIND_ORG: '/rest/v1/account/binding',
	// 验证-生成验证码图片
	VERIFICATION_GENERATE_IMAGE: '/rest/v1/verification/generateImage/:userName',
	// 验证-发送验证码邮件
	VERIFICATION_SEND_MAIL: '/rest/v1/verification/sendMail/:verificationType/:userName',
	// 验证-校验验证码
	VERIFICATION_CODE: '/rest/v1/verification/verify/:verificationType/:verificationCode/:userName',
	// 获取访问OSS的STS Token
	STS_ASK_TOKEN: '/rest/v1/sts/ask/token',
	STS_ASK_URL: '/rest/v1/sts/ask/access_url?filePath=:url',
	// 注册验证-发送验证码邮件
	REGISTER_VERIFICATION_SEND_MAIL: '/rest/v1/verification/sendMailRegister/2/:email',
	// 注册验证-校验验证码
	REGISTER_VERIFICATION_CODE: '/rest/v1/verification/sendMail/:verificationType/:userName',

	QUERY_QUOTE: '/rest/v1/policy/list/desk/criteria',
	QUERY_POLICY: '/rest/v1/policy/list/desk/criteria',
	QUERY_ENDO: '/rest/v1/policy/list/desk/criteria',

	UPLOAD_EXCEL: '/rest/v2/policy/auxiliary/excel/2json',
	DOWNLOAD_EXCEL: '/rest/v2/policy/auxiliary/json/2excel',

	TENANT_CURRENT: '/rest/v1/tenant/current',
	TENANT_CREATE: '/rest/v1/tenant/create',
	TENANT_UPDATE: '/rest/v1/tenant/update',
	TENANT_PROFILE_UPDATE: '/rest/v1/account/menus/tenant/profile/update',
	TENANT_LOAD: '/rest/v1/tenant/load/',
	TENANT_LIST: '/rest/v1/tenant/list/criteria',
	TENANT_DISABLE: '/rest/v1/tenant/disable/:tenantId',
	TENANT_ENABLE: '/rest/v1/tenant/enable/:tenantId',
	TENANT_LIST_ALL: '/rest/v1/tenant/list/all',
	TENANT_CONFIG_UPDATE: '/rest/v1/tenant/configuration/update',
	TENANT_CONFIG_LIST: '/rest/v1/tenant/configuration/list',

	ORGAN_CURRENT: '/rest/v1/organ/current',
	ORGAN_CREATE: '/rest/v1/organ/create',
	ORGAN_UPDATE: '/rest/v1/organ/update',
	ORGAN_LOAD: '/rest/v1/organ/load/',
	ORGAN_LOAD_BYCODE: '/rest/v1/organ/loadByCode/',
	ORGAN_LIST: '/rest/v1/organ/list/criteria',
	ORGAN_LIST_FOR_CREATE: '/rest/v1/organ/list/create/',
	ORGAN_LIST_FOR_EDIT: '/rest/v1/organ/list/edit/',
	ORGAN_UPLOAD_SYNC: '/rest/v1/organ/uploadSync',
	ORGAN_DISABLE: '/rest/v1/organ/disable',
	ORGAN_ENABLE: '/rest/v1/organ/enable',
	ORGAN_LIST_TREE: '/rest/v1/organ/list/tree',

	VENDOR_LIST: '/rest/v1/product/vendor/list',
	VENDOR_AVAILABLE_LIST: '/rest/v1/product/vendor/list/available',
	VENDOR_LIST_CHANNEL: '/rest/v1/dashboard/list/endSalesChannel',

	DASHBOARD_LOAD: '/rest/v1/dashboard/loadShowData',
    DASHBOARD_GENERATE: '/rest/v1/dashboard/generate',

	PRODUCT_LIST_BY_VENDOR: '/rest/v1/product/list/vendors/assignTenant',
	PRODUCT_LIST:'/rest/v1/product/list/criteria',

	AGREE_PRODUCT_LIST: '/rest/v1/agree/product/list/criteria',
	AGREE_PRODUCT_ENABLE: '/rest/v1/agree/product/enable',
	AGREE_PRODUCT_DISABLE: '/rest/v1/agree/product/disable',
	AGREE_PRODUCT_LOAD:'/rest/v1/agree/product/load/:agreementProductId',
	AGREE_PRODUCT_CHANNEL_LIST_PRODUCT:'/rest/v1/agree/product/channel/list/product',
	AGREE_PRODUCT_CHANNEL_UPDATE:'/rest/v1/agree/product/channel/update',

	// 面客平台
	AUTH_SIGNUP2: '/rest/v2/auth/signup', // 用户注册
	AUTH_SIGNIN2: '/rest/v2/auth/signin', // 密码登录
	AUTH_SMS_SIGNIN2: '/rest/v2/auth/smsSignin', // 验证码登录
	SEND_VALIDATE_CODE: '/rest/v1/sms/sendValidateCode', //验证码类型:100 注册,101登录,102 重置密码, 103 修改手机号,104 中台登录,105 个人中心-个人信息编辑
	REFRESH_PIC_CODE: '/rest/v1/sms/refresh/picCode', //刷新图片验证码接口
	VERIFY_PIC_CODE: '/rest/v1/sms/verify/picCode', //校验图片验证码接口
	CODE_VALIDATION: '/rest/v1/sms/codeValidation', // 手机号验证码校验接口
	CHECK_VALIDATION_CODE: '/rest/v1/sms/checkValidationCode', // 校验短信验证码
	RESET_PWD2: '/rest/v2/auth/changePassword', // 重置密码
	ORGAN_QUERY: '/rest/v1/organ/query', // 机构查询

	TENANT_CONFIGURATION_LIST: '/rest/v1/tenant/configuration/list', // 密码策略查询"tenantCode": "test_7", //租户代码 "configType": "1"// 1:密码策略 2:销售区域 3:营销配置
	COOPERATIVE_INSURER_DISCLOSURE: '/rest/v1/product/vendor/disclosure/list/criteria', // 合作保司信息披露加载
	
	PRODUCT_DISCLOSURE: '/rest/v1/product/disclosure/list/criteria', // 互联网产品信息披露加载
	PRODUCT_DISCLOSURE_DISABLE: '/rest/v1/product/disclosure/disable',
	PRODUCT_DISCLOSURE_ENABLE: '/rest/v1/product/disclosure/enable',
	PRODUCT_DISCLOSURE_LOAD: '/rest/v1/product/disclosure/load/:dataId',
	PRODUCT_DISCLOSURE_UPDATE: '/rest/v1/product/disclosure/update',

	PRODUCT_VENDOR_DISCLOSURE: '/rest/v1/product/vendor/disclosure/list/criteria',//合作保司查询列表
	PRODUCT_VENDOR_DISCLOSURE_DISABLE: '/rest/v1/product/vendor/disclosure/disable',
	PRODUCT_VENDOR_DISCLOSURE_ENABLE: '/rest/v1/product/vendor/disclosure/enable',
	PRODUCT_VENDOR_DISCLOSURE_LOAD: '/rest/v1/product/vendor/disclosure/load/:dataId',
	PRODUCT_VENDOR_DISCLOSURE_UPDATE: '/rest/v1/product/vendor/disclosure/update',


	// 会员账号管理 & 管理员账号
	MEMBER_LIST:'/rest/v2/admin/urp/account/list/criteria',
	MEMBER_EDIT:'/rest/v2/admin/urp/account/update',
	MEMBER_LOOK:'/rest/v2/admin/urp/account/detail',
	MEMBER_DISABLE:'/rest/v2/admin/urp/account/disable',
	MEMBER_ENABLE:'/rest/v2/admin/urp/account/enable',
	MEMBER_ADD:'/rest/v2/admin/urp/account/create',
	ADMIN_LIMITS:'/rest/v2/admin/urp/account/grant',//管理员角色绑定
	ADMIN_ROLE:'/rest/v2/admin/urp/account/roles',
	// 角色管理
	ROLE_LIST:'/rest/v2/admin/urp/role/list',
	ROLE_LIST_ROLE:'/rest/v2/admin/urp/account/roles',//已有的角色&用于管理员角色权限


	QUERY_PRODUCT_LIST: '/rest/v1/product/listByChannel', // 根据查询条件列出所有产品
	QUERY_PRODUCT_LIST_BY_KEYWORD: '/rest/v1/product/list/keyword/:keyword', // 根据指定的关键字列出所有产品(已登录用户访问)
	QUERY_PRODUCT_LIST_BY_KEYWORD_NOT_LOGIN: '/rest/v1/product/listNotLogin/keyword/:keyword', // 根据指定的关键字列出所有产品(未登录用户访问)
	DIRECT_ASK: '/rest/v1/tenant/direct/ask/',
	QUERY_PRODUCT_TAG: '/rest/v1/product/tag/list3rdByProductJoint', // 查询已关联产品的三级标签 (未登录必传tenantCode)

	QUERY_ACCOUNT_MASK: '/rest/v1/account/mask', // 获取个人信息掩码
	QUERY_ACCOUNT_INFO: '/rest/v1/account/current', // 获取个人信息
	UPDATE_ACCOUNT: '/rest/v1/account/update', // 编辑个人信息
	MODIFY_MOBILE: '/rest/v2/auth/changeMobile', // 验证码校验以及手机号修改
	DISABLE_ACCOUNT: '/rest/v2/auth/disable/:accountId', // 注销用户

	POLICY_LIST_BY_STATUS: '/rest/v1/policy/list/my/mall/status', // 1.	根据状态查询订单/保单接口
	ACCOUNT_AVATAR: '/rest/v1/account/avatar', // 更新头像

	POLICE_VERIFY_WITHOUT_FACE: '/rest/detect/idCardDetect', // 二要素 身份认证(身份证+姓名){name，''；cardId：''}
	AGREE_PRODUCT_CHANNEL_LIST_ORGAN_LIST_ORGAN_AVAILABLE: '/rest/v1/agree/product/channel/list/organ/available',

	// 搜索历史
	CREATE_SEARCH_HISTORY: '/rest/client/product/search/history/create',
	QUERY_SEARCH_HISTORY: '/rest/client/product/search/history/list',
	DELETE_SEARCH_HISTORY: '/rest/client/product/search/history/delete', // get 无参数

	SEND_EMAIL: '/rest/v2/account/settings/sendMail', // POST 设置是否接受邮件 {"enableSendEmail":true}

	FAMILY_MEMBER_LIST_ACCOUNT_ID: '/rest/v1/familyMember/list/accountId',
	FAMILY_MEMBER_UPDATE: '/rest/v1/familyMember/update',
	FAMILY_MEMBER_DELETE: '/rest/v1/familyMember/delete',

	GET_RENEW_POLICYIDS: '/rest/v1/policy/my/getRenewablePolicyIds',
	GET_VENDOR_URL : '/rest/v2/policy/getVendorUrl/:vendorCode/:policyId/:type',
} as APIS;
