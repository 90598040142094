// import 'react-app-polyfill/stable';
import { React, ReactDOM } from '@common/3rd';
// import {Envs} from './common';
import App from './app';
// import registerServiceWorker from './registerServiceWorker';

/**
 * 定义全局对象
 */
declare global {
	interface Window {
		whiteScreen: EventListener[];
		__wxjs_environment?: string;
		__EBAO_JSSDK?: {
			callNative: (command: string) => void;
		};
		webkit?: {
			messageHandlers?: {
				__EBAO_JSSDK?: {
					postMessage: (command: string) => void;
				};
			};
		};
	}
}

if (window.whiteScreen) {
	['touchstart', 'touchend'].forEach(name =>
		window.whiteScreen.forEach(func => window.removeEventListener(name, func))
	);
	// @ts-ignore
	delete window.whiteScreen;
}

// add polyfill
if (!Array.prototype.flat) {
	// eslint-disable-next-line
	Array.prototype.flat = function() {
		var depth = arguments[0];
		depth = depth === undefined ? 1 : Math.floor(depth);
		if (depth < 1) return Array.prototype.slice.call(this);
		return (function flat(arr, depth) {
			// @ts-ignore
			var len = arr.length >>> 0;
			var flattened = [] as any[];
			var i = 0;
			while (i < len) {
				if (i in arr) {
					// @ts-ignore
					var el = arr[i];
					// @ts-ignore
					if (Array.isArray(el) && depth > 0) flattened = flattened.concat(flat(el, depth - 1));
					else flattened.push(el);
				}
				i++;
			}
			return flattened;
		})(this, depth);
	};
}

console.time('Render whole application.');
ReactDOM.render(<App />, document.getElementById('root'), () => {
	console.timeEnd('Render whole application.');
});



